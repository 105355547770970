<template>
    <div class="nav-rights">

        <!-- <div class="box222" @click="goHome()">

        </div> -->

        <div class="headBox">
            <img src="../assets/newImage/back.png" alt="" style="width:20px;height:15px;" @click="goback">
            <span>
            四名宝
      </span>
            <span>{{  }}</span>
        </div>
        <List item-layout="vertical" style="margin-top: 55px;">
            <ListItem v-for="item in tableData" :key="item.title"
                      style="display: flex;flex-direction: column;align-items: center;width: 100%;padding: 10px;">
                <div
                    style="display: flex; justify-content: space-between;width: 100%;color: aliceblue;background-color: #021427;padding: 5px;">
                    <span>{{ item.tableId }} 号桌</span>
                    <span>荷官:{{ item.operator }}</span>
                    <span>状态:{{ item.tablestate }}</span>
                </div>
                <div style="width: 100%; display: flex;margin-top: 5px;"
                     @click="gotoTable(item.operator, item.tableId, item.videoLink, item.betTime, item.currentPeriod.period,item.stage)">
                    <img :src="item.avatar" style="width: 25%;height: 100%;">

                    <div class="roadmap">
                        <div v-if="item.stage < 0" class="disablestatus">

                        </div>
                        <div class="cardroad_list" v-for="item in roadData[item.tableId]" :key="item.index">
                            <div class="cardroad_item" v-for="item1 in item" :key="item1.index">


                                <img v-if="item1 == '1'" src="../assets/newImage/1.svg" style="width: 100%;">
                                <img v-if="item1 == '2'" src="../assets/newImage/2.svg" style="width: 100%;">
                                <img v-if="item1 == '3'" src="../assets/newImage/3.svg" style="width: 100%;">
                                <img v-if="item1 == '4'" src="../assets/newImage/4.svg" style="width: 100%;">
                            </div>
                        </div>


                    </div>

                </div>

                <div style="display: flex; width: 100%;color: aliceblue;background-color: #021427;;">
                    <span>个人限红:{{ item.limitBet }}</span>
                </div>
                <div style="display: flex; width: 100%;color: aliceblue;background-color: #021427;">
                    <span>台桌限红:{{ item.limitRed }}</span>
                </div>


            </ListItem>
        </List>


        <!-- <drag-ball :value="message" @click="goHome()" style="background: transparent ;width: 40px;height: 40px;">
          <div slot="value">
              <img src="../assets/netmage/back22.png" width="40px" height="40px" alt="">
          </div>
      </drag-ball> -->

        <!-- <div style="height:100%">
          <iframe style="width:100%; height:100%;" :src="outurl" frameborder="0" id="gameframe"></iframe>
        </div> -->


    </div>
</template>

<script>
export default {
    name: 'gamePage',
    data() {
        return {
            outurl: '',
            windowWidth: document.documentElement.clientWidth,  //实时屏幕宽度
            windowHeight: document.documentElement.clientHeight,   //实时屏幕高度
            message: '',
            tableData: null,
            limitConfig: {}, //限红设置

            roadData: [[]]

        }
    },
    created() {
        // let id = this.$route.query.gid
        // if (this.$store.getters.isNobody == true) {
        //   this.outurl = 'https://www.waliyouxi.com/trial/trial.html?game=' + id

        // } else {
        //     if(this.$route.query.gpage == undefined) console.log("undefined") ;
        //   this.outurl = this.$route.query.gpage;
        //   console.log("ssss " + this.outurl)
        // }

        this.getTables()
        this.getGameConfig()
    },

    methods: {
        getTables() {  //获取桌子
            let that = this
            this.$http.get(this.host + this.api.game.getTables).then(response => {
                let resp = response.body;
                console.log(resp)
                if (resp.code == 200) {
                    // this.$Message.success(resp.mesg);
                    // this.isdisable = true
                    that.tableData = resp.data
                    that.handleTableData(that.tableData)
                    console.log(that.tableData);
                } else {
                    // this.$Message.error(resp.mesg);
                    // this.$Message.error(this.$t('common.logintip'));
                }
            });
        },
//         {
//     "tableId": "1",
//     "operator": "opera_1",
//     "stage": 2,
//     "users": [
//         "4239353_test",
//         "8452032_test"
//     ],

// }
        handleTableData(tables) {
            for (const i in tables) {
                let element = tables[i]
                element.players = element.users.length
                //    element.avatar = require('@/assets/newImage/1.png')
                this.getGameConfig(element)
                switch (element.stage) {
                    case -1:
                        element.tablestate = '台桌关闭'
                        break;
                    case 0:
                        element.tablestate = '台桌开始'
                        break;
                    case 1:
                        element.tablestate = '开始下注'
                        break;
                    case 2:
                        element.tablestate = '结束下注'
                        break;
                    case 3:
                        element.tablestate = '结算'
                        break;
                    default:
                        break;
                }

                this.handleRoadata(element.historyPeriod, element.tableId)
            }

        },

        handleRoadata(road, id) {
            let temp1 = this.roadData[id]
            if (temp1 == null || temp1 == undefined) {
                temp1 = []
            }
            for (const i in road) {
                let element = road[i]

                const length = temp1.length;
                console.log('road single colomn ' + length);
                const [lastValue] = temp1.slice(-1);
                if (!lastValue || lastValue.length <= 0) {
                    let xx = []
                    xx.push(element.result)
                    temp1.push(xx)
                } else if (lastValue.length >= 6) {
                    let xyz = []
                    xyz.push(element.result)
                    temp1.push(xyz)
                } else {
                    const [ll1] = lastValue.slice(-1)
                    if (!ll1 || ll1.length <= 0) {
                        console.error('!!!!!!!!!!');
                    } else {
                        if (Number(ll1) % 2 == 0) {
                            if (Number(element.result) % 2 == 0) {
                                lastValue.push(element.result)
                            } else {
                                let yy = []
                                yy.push(element.result)
                                temp1.push(yy)
                            }
                        } else {
                            if (Number(element.result) % 2 == 1) {
                                lastValue.push(element.result)
                            } else {
                                let yy = []
                                yy.push(element.result)
                                temp1.push(yy)
                            }
                        }
                    }

                }
            }

            let yy = 0
            let temp = []
            for (let i = temp1.length - 1; i >= 1; i--, yy++) {
                if (yy >= 16) {
                    break
                }
                temp.push(temp1[i])

            }
            this.roadData[id] = temp.reverse()
            console.log('sdfsd+ ');
        },

        getGameConfig(element) { //获取游戏配置
            if (!element) {
                return
            }
            let that = this
            let param = {}
            param.tableId = element.tableId
            this.$http.get(this.host + this.api.game.getConfig, {params: param}).then(response => {
                let resp = response.body;
                if (!response.ok) {
                    that.$Message.error('获取游戏配置异常');
                } else {
                    element.limitBet = ''
                    element.limitRed = ''
                    element.videoLink = resp.videoLink
                    element.betTime = resp.betTime
                    for (const i in resp.limitBet) {
                        element.limitBet += i + ':' + resp.limitBet[i] + ' '
                    }
                    for (const j in resp.limitRed) {
                        element.limitRed += j + ':' + resp.limitRed[j] + ' '
                    }
                    that.$forceUpdate()
                }
            });

            console.log('ssss' + element);
        },
        renderResize() {
            let iwidth = document.documentElement.clientWidth;
            let iHeight = document.documentElement.clientHeight;
            const iggame = document.getElementById('gameframe');
            if (iwidth > iHeight) {

                iggame.style.height = (Number(iHeight)) + 'px'
                iggame.style.width = (Number(iwidth)) + 'px'
            } else {
                iggame.style.height = (Number(iHeight)) + 'px'
                iggame.style.width = (Number(iwidth)) + 'px'
            }
        },

        gotoTable(index1, index2, link, betTime, period, stage) {
            if (stage < 0) {
                return
            }
            this.$router.push({
                path: '/gameTable',
                query: {gpage: index2, page1: index1, pp: link, pp1: betTime, pp2: period}
            })

        },

        getMoneyback() {
//  let self = this;
//     this.$http
//         .post(this.host + this.api.uc.apply)
//         .then(response => {
//           let resp = response.body;
//           if (resp.code == "0000") {
//             // this.getMember();
//             this.$router.replace("/");
//           } else {
//             self.$Notice.error({
//                     title: this.$t("common.tip"),
//                     desc:resp.mesg
//                   });
//           }
//         });
        },
        getMember() {
            //获取个人安全信息
            let self = this;

            this.$http
                .post(self.host + this.api.uc.personalWallet)
                .then(response => {
                    let resp = response.body;
                    if (resp.code == "0000") {

                        resp.data.forEach(element => {
                            if (element.ccy == 'USDT') {
                                self.moneybab = element

                            }
                        });

                    } else {
                        self.$Notice.error({
                            title: this.$t("common.tip"),
                            desc: resp.mesg
                        });
                    }
                });
        },

        goback() {
            //获取个人安全信息
            this.$router.back(-1)

        },

        goHome() {
            if (this.$store.getters.isNobody == true) {
                this.$router.replace("/");
                return
            } else
                this.getMoneyback();
        }
    }
}


</script>
<style>
body {
    background-color: transparent;
}
</style>
<style lang="scss" scoped>
.nav-rights {
    background: transparent;
    height: 100vh;
    position: relative;
    overflow: hidden;

}

.disablestatus {
    position: absolute;
    width: 101%;
    height: 25%;
    // top: 20%;
    z-index: 100;
    left: 0;
    align-items: center;
    justify-content: center;
    color: #FFF;
    background-color: rgba(0, 0, 0, 0.4);
}


.roadmap {
    background: url(../assets/newImage/cardroad_bg_16.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 90%;
    width: 80%;
    display: flex;
    padding-top: 0.01rem;
    padding-bottom: 0.01rem;
}


.cardroad_list {
    width: 6.25%;
    height: 90%;
    display: flex;
    flex-direction: column;
    margin-top: 2%;
}

.cardroad_item {
    width: 100%;
    height: 16.66%;
    display: flex;
    align-items: center;
    justify-content: center;
    scale: 0.95;
}

</style>

