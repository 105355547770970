<template>
    <div class="home-list">
        <div class="head">
            <div style="width: 20%"></div>
            <img class="title-img" src="../assets/ting/logo-line.png" alt="">
            <Icon style="width: 20%" class="withdraw" type="ios-download-outline" @click="showConfirm"/>
        </div>
        <div class="box">
            <div class="con">
                <div class="game-list">
                    <div class="game-item" style="display: flex">
                        <img class="ting-img" src="../assets/ting/ting.png" alt="">
                        <div class="right" style="display: flex">
                            <div class="t">赌场视讯</div>
                            <div class="read"> 拥有专业国际研制的顶尖游戏产品，仿佛置身于现场的视觉与听觉享受。</div>
                            <div class="enter-game-btn" @click="goGame">
                                进入游戏
                                <Icon style="padding: 0 8px;font-size: 20px" type="md-arrow-dropright-circle"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'HomeList',
    data() {
        return {}
    },
    methods: {
        showConfirm() {
            this.$Modal.confirm({
                title: "提示",
                content: "确定要退出吗？",
                onOk: () => {
                    this.logout()
                },
            });
        },
        goGame() {
            this.$router.push({path: "/about"})
        },
        logout() {
            let self = this
            this.$http.post(self.host + this.api.uc.toppromotionmonth).then((response) => {
                let resp = response.body
                if (resp.code == '0000') {
                    self.$Message.success(resp.mesg)
                    self.$store.commit('setMember', null)
                    localStorage.setItem('MEMBER', null)
                    localStorage.setItem('TOKEN', null)
                    localStorage.removeItem('USERKEY', null)
                    self.$router.replace({path: '/'})
                } else {
                    self.$Message.error(resp.mesg)
                }
            })
        },
    }
}
</script>


<style>
.home-list {
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    background-image: url("../assets/bg.jpg");

    .head {
        height: 80px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title-img {
            height: 40px;
            margin: 20px;
        }

        .withdraw {
            font-size: 26px;
            font-weight: 900;
            transform: rotate(-90deg)
        }
    }

    .box {
        padding-top: 10px;
        width: 100%;

        .con {
            padding: 20px 20px 20px 20px;

            .game-list {
                border-radius: 10px;
                padding: 20px 0 20px 20px;
                box-shadow: 0 0 26px hsla(0, 0%, 100%, .15);
                display: flex;
                flex-direction: column;
                justify-content: center;

                .game-item {
                    width: 100%;
                    display: flex;

                    .ting-img {
                        width: 124px;
                        height: 142px;
                    }

                    .right {
                        padding: 0px 14px;
                        display: flex;
                        flex-direction: column;
                        text-align: left;
                        justify-content: space-between;

                        .t {
                            font-size: 16px;
                            color: #fcefbc;
                            border-bottom: 1px solid #fcefbc;
                        }

                        .read {
                            font-size: 10px;
                            line-height: 20px;
                        }

                        .enter-game-btn {
                            color: #FCEFBC;
                            text-align: center;
                            width: 124px;
                            position: relative;
                            display: -webkit-box;
                            display: -ms-flexbox;
                            display: flex;
                            -webkit-box-align: center;
                            -ms-flex-align: center;
                            align-items: center;
                            margin-right: .0rem;
                            padding: .375rem 0rem .375rem 1rem;
                            background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
                            --tw-bg-opacity: 0.1;
                            align-self: flex-end;
                        }
                    }
                }
            }
        }
    }
}
</style>
