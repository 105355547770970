<template>
  <div class="nav-rights">
     
   <div class="headBox">
     <img src="../assets/newImage/back.png" alt="" style="width:20px;height:20px;" @click="goback">
       <span  style="font-size:20px;">
         {{$t('common.service')}}
       </span>
       <span>{{  }}</span>
     </div>
 
       <div style="height:100%;margin-top: 40px;" >
         <iframe style="width:100%; height:calc(100% - 40px)" :src=customerUrl frameborder="0" id="gameframe"></iframe>
       </div>
 
 
  </div>
 </template>
 
 <script>
 
 export default {
     name:'CustomService',
   
     data() {
     return {
       customerUrl:'',
     
     }
     },
     mounted(){
     window.scrollTo(0, 0);
     const iggame = document.getElementById('gameframe');
     const windowHeight = document.documentElement.clientHeight;
     iggame.style.height = Number(windowHeight)+'px'
 
     // this.$store.getters.member.userNo
 
    //  if (this.$store.getters.member == null) {
    //   this.customerUrl = this.$route.params.urlxx
       
    //  }else{
    //    console.log('sdfsd'+this.$store.getters.member.userNo);
      
       this.customerUrl = "http://154.207.104.9:18081/chatIndex?kefu_id=kefu2&ent_id=2&visitor_id="+this.$store.getters.member.userNo
    //  }
 
   },
     methods: {
   goback() {
       //获取个人安全信息
       this.$router.back(-1)
      
     },
 
 
   }
 
 }
 </script>
 
 
 <style lang="scss" scoped>
 .nav-rights {
   background: transparent;
   height: $full-page;
   position: relative;
   overflow: hidden;
 
 
   .box{
 position: fixed;
   top: 0;
   display: flex;
   height: 50px;
   // padding: 10px;
   align-items: center;
   justify-content: space-between;
   color: $color-text-tag;
   z-index: 200;
    width: 100%;
 }
 }
 </style>