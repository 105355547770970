<template>
 <div class="login_form">
   <div class="headBox">
      <span style="color:aliceblue;font-size:20px;">
        {{$t('common.message')}}
      </span>
      <span>{{  }}</span>
    </div>
  <Main-tab-bar></Main-tab-bar>

 </div>
</template>

<script>

import MainTabBar from '../components/Tabbar/MainTabBar.vue'
export default {
  components: {
MainTabBar
  },
}
</script>

<style lang="scss" scoped>

</style>

