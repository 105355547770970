<script>
export default {
    name: "",
    data() {
        return {
            single: [2, 3, 1, 4],
            luListSingle: [],
        }
    },
    props: {
        historyList: {
            default: []
        }
    },
    watch: {
        historyList(newVal, old) {
            this.getList()
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        getList() {
            console.log( '开奖' )
        },
        sortBet(a){
            if(Object.getOwnPropertyNames(a).length === 1) {  //有的数据没有看到
                return [1,2,3,4]
            }else{
                let t = Object.entries(a)
                    .sort(([, value1], [, value2]) => value2 - value1)// 按值从大到小排序
                    .map(([key]) => Number(key));  // 提取排序后的键
                return t;
            }
        }
    }
}
</script>

<template>
    <div class="out-box">
        <div class="head_box">
            <div class="r_item" v-for="(item,i) in 5" :key="i">
                {{ i > 0 ? i : '' }}
            </div>
        </div>
        <div class="lu-box">
            <div class="list list1">
                <div class="list_col" v-for="(item,i) in historyList" :key="i">
                    <div class="r_item">{{ i + 1 }}</div>
                    <div class="r_item" :class="{openR : item.result == n+1}" v-for="(tem,n) in sortBet(item.betNumOddTotal)" :key="n" >
                        <img :src="require(`../assets/room/s_${tem}.png`) " alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.out-box {
    width: 90%;
    margin: auto;
    height: 100%;
    display: flex;

    .lu-box {
        overflow-x: auto;
        width: calc(100% - 20px);
        height: 100%;
        display: flex;
        flex-direction: column;

        .list {
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: start;

            .list_col {
                .r_item {
                    background-color: #fff;
                    width: 20px;
                    height: 20px;
                    border: 1px solid #EBC578;
                    box-sizing: border-box;
                    border-right: 0;
                    border-bottom: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #8B6F00;
                    font-size: 10px;
                    background-repeat: no-repeat;
                    background-size:100% 100%;
                    background-position: center center;
                }

                .openR{  //开奖号码背景
                    background-image: url('../assets/room/moon.png');
                }

                .r_item:last-child {
                    border-right: 1px solid #EBC578;
                }

                .r_item {
                   &> img{
                        //width: 10px;
                        height: 14px;
                    }
                }
            }
        }
    }

    .head_box {
        width: 20px;
        height: 100%;
        background-color: #fff;

        .r_item {
            width: 20px;
            height: 20px;
            border: 1px solid #EBC578;
            box-sizing: border-box;
            border-right: 0;
            border-bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #8B6F00;
            font-size: 10px;
        }
    }
}


</style>
