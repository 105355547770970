<template>
    <div class="box">
        <div class="nav-rights" @contextmenu.prevent>
            <Spin v-if="loading" fix class="demo-spin-col">
                <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>
                <div style="color: #fff">台桌加载中…</div>
            </Spin>
            <div class="box-top">
                <div class="box_21">
                    <div class="back-img">
                        <img src="../assets/netmage/arrowleft.png" alt="" style="width: 30px" @click="goBack">
                    </div>
                    <!--                    <div class="title">四名宝：0{{ id }}</div>-->
                    <div style="flex: 1;">
                        <img @click="del(2)" class="regular" src="../assets/room/reg.png" alt="">
                    </div>
                    <div class="balance">
                        <img style="width: 16px" src="../assets/room/coin-person.png" alt="">
                        {{ person }}
                    </div>
                </div>
                <!--        视频区-->
                <div class="box-video">
                    <!--声音-->
                    <div class="icon-voice" style="color: #fff" @click="changeVoice"
                         :class="{'icon-voice-line':voice === 0}">
                        🔊
                    </div>
                    <div class="cover" :class="{'flipAnimatioCover': isFlipped}"></div>
                    <div v-if="openR != 0 || 1" class="cover cover1" :class="{'flipAnimatioCover1': isFlipped}"
                         :style="{backgroundImage:'url(' + require(`../assets/room/r_${openR || 1}.png`)  + ')'}"></div>
                    <div class="desc-popup">
                        <div v-if="stage == 2 || stage == 3" class="tableStatus"> {{ ['', '', '停止下注', '结算'][stage]
                            }}
                        </div>
                        <div v-else class="desc-num tableStatus">{{ descTime }}</div>
                    </div>
                    <div class="result" style="opacity: .4;">
                        本局哈希值：{{ currentInfo.has }}
                        <Icon @click="handleCopy(currentInfo.has)" type="md-albums"/>
                    </div>
                    <div class="result2" style="opacity: .4;">
                        开奖号码：{{ hashR }}
                        <Icon @click="handleCopy(hashR)" type="md-albums"/>
                    </div>
                    <div class="person"><img style="height: 16px" src="../assets/room/coin-balance.png" alt="">
                        <span>{{ moneybab.amount }}</span>
                    </div>
                </div>
            </div>
            <div v-if="showText" class="popup-txt">{{ showTextCon }}</div>
            <Modal v-model="modal2" width="360">
                <div style="text-align:center">
                    <div class="pop-box">
                        <div class="top">
                            <div class="t-l">游戏规则</div>
                            <Icon @click="del(2)" class="t-r" style="color: #fff" type="md-close"/>
                        </div>
                        <div class="con">
                            <div class="des">
                                <div class="t-title">简介 INTRODUCTION</div>
                                <div class="c-text">
                                    四名宝是中国古老的做庄博彩游戏，流行于中国沿海一带，当年四名宝只有一种玩法，就是猜数字玩家时至今日，四名宝玩法已改变不少，除了数字之外还多了念、角、通、门、单双这几种玩法
                                    。
                                </div>
                            </div>
                        </div>
                        <div class="con">
                            <div class="des">
                                <div class="t-title">游戏玩法 GAME INSTRUCTION</div>
                                <li class="c-text">
                                    游戏使用四张扑克牌，新局开始后，荷官从扑克牌(1,2,3,4)中随机抽出的一张扑克牌作为开奖结果，开始下注。
                                </li>
                                <li class="c-text">
                                    下注结束后，荷官即翻开扑克牌
                                </li>
                                <li class="c-text">扑克牌的点数对应桌面的数字猜对为赢，同时画面会显示玩家的输赢情况。</li>
                            </div>
                        </div>
                        <div class="con">
                            <div class="des">
                                <div class="t-title">派彩 PAYOFF</div>
                                <table class="table table-bordered text-middle">
                                    <thead>
                                    <tr>
                                        <th style="width: 25%" class="text-center col-md-2"><span
                                            class="mceNonEditable dict">下注组合</span>
                                        </th>
                                        <th style="width: 15%" class="text-center col-md-2"><span
                                            class="mceNonEditable dict">赔率</span></th>
                                        <th style="width: 68%;text-align: center" class="text-center col-md-8"><span
                                            class="mceNonEditable dict">说明</span></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(item,i) in read" :key="i">
                                        <td class="text-center td-theme"><span class="mceNonEditable rule_txt"
                                                                               id="rule_txt[17]">{{ item.n }}</span>
                                        </td>
                                        <td class="text-center">{{ item.v }}</td>
                                        <td class="text-left" style="text-align: center;width: 68%"><span
                                            class="mceNonEditable rule_txt" id="rule_txt[9]">
                                            {{ item.c }}
                                           </span>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="con">
                            <div class="des des-ol">
                                <div class="t-title">其他补充说明 EXPLANATION</div>
                                <ol class="c-text">
                                    本游戏进行方式为线上线下同时进行，若有发生特殊情形将依本公司公告之办法处理。
                                </ol>
                                <ol class="c-text">
                                    如在游戏中发生系统故障或者其他不可不免的意外，该局判定为无效，并退还所有下注本金。
                                </ol>
                                <ol class="c-text">
                                    四名宝游戏进行每90局后，系统会自动清除牌路。
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal v-model="modal1" width="260" class="model-notice">
                <div style="text-align:center">
                    <div class="pop-box pop-box-notice">
                        <div class="top">
                            <div class="t-l">提示</div>
                            <Icon @click="del(1)" class="t-r" style="color: #fff" type="md-close"/>
                        </div>
                        <div class="con">
                            <p>
                                <Icon type="ios-warning-outline"/>
                                {{ modalText }}
                            </p>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal v-model="modal4" width="260" class="model-notice">
                <div style="text-align:center">
                    <div class="pop-box pop-box-notice">
                        <div class="top">
                            <div class="t-l">提示</div>
                            <!--                            <Icon @click="del(4)" class="t-r" style="color: #fff" type="md-close"/>-->
                        </div>
                        <div class="con">
                            <p>
                                开奖结果： {{ openR }}
                            </p>

                            <div v-if="total > 0" :style="{color:win >= 0 ?'rgba(173, 255, 47, 1' : 'red'}"
                                 class="popup-txt popup-txt-win">输赢 ：{{ win }}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal v-model="modal3" width="260" class="model-notice">
                <div style="text-align:center" v-if="limitConfig && limitConfig.limitBet">
                    <div class="pop-box pop-box-notice">
                        <div class="top">
                            <div class="t-l">提示</div>
                            <Icon @click="del(3)" class="t-r" style="color: #fff" type="md-close"/>
                        </div>
                        <div class="con1">
                            <div style="height: 30px;line-height: 30px">个人限注</div>
                            <div>
                                <Input disabled v-model="limitConfig.limitBet['A']"
                                       style="width: 150px;margin:4px auto;">
                                    <span slot="prepend">A区</span>
                                </Input>
                                <Input disabled v-model="limitConfig.limitBet['B']"
                                       style="width: 150px;margin:4px auto;">
                                    <span slot="prepend">B区</span>
                                </Input>
                                <Input disabled v-model="limitConfig.limitBet['C']"
                                       style="width: 150px;margin:4px auto;">
                                    <span slot="prepend">C区</span>
                                </Input>
                                <Input disabled v-model="limitConfig.limitBet['D']"
                                       style="width: 150px;margin:4px auto;">
                                    <span slot="prepend">D区</span>
                                </Input>
                            </div>
                            <div style="height: 30px;line-height: 30px">游戏限红</div>
                            <div>
                                <Input disabled v-model="limitConfig.limitRed['A']"
                                       style="width: 150px;margin:4px auto;">
                                    <span slot="prepend">A区</span>
                                </Input>
                                <Input disabled v-model="limitConfig.limitRed['B']"
                                       style="width: 150px;margin:4px auto;">
                                    <span slot="prepend">B区</span>
                                </Input>
                                <Input disabled v-model="limitConfig.limitRed['C']"
                                       style="width: 150px;margin:4px auto;">
                                    <span slot="prepend">C区</span>
                                </Input>
                                <Input disabled v-model="limitConfig.limitRed['D']"
                                       style="width: 150px;margin:4px auto;">
                                    <span slot="prepend">D区</span>
                                </Input>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <div style="width: 0;height: 0;overflow: hidden">
                <audio ref="audioTagStart" controls>
                    <source src="../assets/sound/start.mp3" type="audio/mpeg">
                    Your browser does not support the audio element.
                </audio>
                <audio ref="audioTagStop" controls>
                    <source src="../assets/sound/stop.mp3" type="audio/mpeg">
                    Your browser does not support the audio element.
                </audio>
                <audio ref="audioTag1" controls>
                    <source src="../assets/sound/1.mp3" type="audio/mpeg">
                    Your browser does not support the audio element.
                </audio>
                <audio ref="audioTag2" controls>
                    <source src="../assets/sound/2.mp3" type="audio/mpeg">
                    Your browser does not support the audio element.
                </audio>
                <audio ref="audioTag3" controls>
                    <source src="../assets/sound/3.mp3" type="audio/mpeg">
                    Your browser does not support the audio element.
                </audio>
                <audio ref="audioTag4" controls>
                    <source src="../assets/sound/4.mp3" type="audio/mpeg">
                    Your browser does not support the audio element.
                </audio>
            </div>
        </div>
        <div class="bet-box">
            <div class="bet-box-con">
                <div class="bet-top">
                    <div class="bet-left">
                        <div class="bet-left-t">
                            <div class="item" :class="getClassNames(i)" v-for="(item,i) in txtList.slice(0,12)" :key="i"
                                 @click="betBegin(i)">
                                <span>{{ item }}</span>
                                <div class="bet-show">
                                    <span>{{ betListSure[i] }}</span>
                                </div>
                                <!--自己的下注 待确认-->
                                <div class="my-bet"
                                     v-if="betListWait[i] > 0">
                                    <!--<img src="../assets/room/coin-balance.png" alt="">-->
                                    <span>{{ betListWait[i] }}</span>
                                </div>
                                <!--我方已经确认的下注-->
                                <div class="bet-show1" v-if="betListMySure[i] > 0">
                                    <span>{{ betListMySure[i] }}</span>
                                    <!--<img style="width: 20px" src="../assets/room/coin-balance.png" alt="">-->
                                </div>
                            </div>
                        </div>
                        <div class="bet-left-b">
                            <div class="jiao">
                                <div class="item" :class="getClassNames(i+12)" v-for="(item,i) in txtList.slice(12,16)"
                                     :key="i"
                                     @click="betBegin(i + 12)">
                                    <span>{{ item }}</span>
                                    <div class="bet-show">
                                        <span>{{ betListSure[i + 12] }}</span>
                                    </div>
                                    <!--自己的下注 待确认-->
                                    <div class="my-bet"
                                         v-if="betListWait[i+ 12] > 0">
                                        <!--<img src="../assets/room/coin-balance.png" alt="">-->
                                        <span>{{ betListWait[i + 12] }}</span>
                                    </div>
                                    <!--我方已经确认的下注-->
                                    <div class="bet-show1" v-if="betListMySure[i+ 12] > 0">
                                        <span>{{ betListMySure[i + 12] }}</span>
                                        <!--<img style="width: 20px" src="../assets/room/coin-balance.png" alt="">-->
                                    </div>
                                </div>
                            </div>
                            <div class="dan_s">
                                <div class="item" :class="getClassNames(i+16)" v-for="(item,i) in txtList.slice(16,18)"
                                     :key="i"
                                     @click="betBegin(i + 16)">
                                    <span>{{ item }}</span>
                                    <span>1:0.97</span>
                                    <div class="bet-show">
                                        <span>{{ betListSure[i + 16] }}</span>
                                    </div>
                                    <!--自己的下注 待确认-->
                                    <div class="my-bet"
                                         v-if="betListWait[i + 16] > 0">
                                        <!--<img src="../assets/room/coin-balance.png" alt="">-->
                                        <span>{{ betListWait[i + 16] }}</span>
                                    </div>
                                    <!--我方已经确认的下注-->
                                    <div class="bet-show1" v-if="betListMySure[i + 16] > 0">
                                        <span>{{ betListMySure[i + 16] }}</span>
                                        <!--<img style="width: 20px" src="../assets/room/coin-balance.png" alt="">-->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bet-right">
                        <div class="item" :class="getClassNames(i+18)" v-for="(item,i) in txtList.slice(18,30)" :key="i"
                             @click="betBegin(i + 18)">
                            <span>{{ item }}</span>
                            <div class="bet-show">
                                <span>{{ betListSure[i + 18] }}</span>
                            </div>
                            <!--自己的下注 待确认-->
                            <div class="my-bet"
                                 v-if="betListWait[i + 18] > 0">
                                <!--<img src="../assets/room/coin-balance.png" alt="">-->
                                <span>{{ betListWait[i + 18] }}</span>
                            </div>
                            <!--我方已经确认的下注-->
                            <div class="bet-show1" v-if="betListMySure[i + 18] > 0">
                                <span>{{ betListMySure[i + 18] }}</span>
                                <!--<img style="width: 20px" src="../assets/room/coin-balance.png" alt="">-->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bet-bottom">
                    <div class="item" :class="getClassNames(i+30)" v-for="(item,i) in txtList.slice(-4)" :key="i"
                         @click="betBegin(i + 30)">
                        <span>{{ item }}</span>
                        <span>1:2.91</span>
                        <div class="bet-show">
                            <span>{{ betListSure[i + 30] }}</span>
                        </div>
                        <!--自己的下注 待确认-->
                        <div class="my-bet"
                             v-if="betListWait[i + 30] > 0">
                            <!--<img src="../assets/room/coin-balance.png" alt="">-->
                            <span>{{ betListWait[i + 30] }}</span>
                        </div>
                        <!--我方已经确认的下注-->
                        <div class="bet-show1" v-if="betListMySure[i + 30] > 0">
                            <span>{{ betListMySure[i + 30] }}</span>
                            <!--<img style="width: 20px" src="../assets/room/coin-balance.png" alt="">-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--筹码区域-->
        <div class="bet-coin-list">
            <!--取消 确认-->
            <img @click="opt(5)" class="arr-r arr" src="../assets/room/cancel.png" alt="">
            <div class="box-list">
                <div class="bet-coin-list-box">
                    <img
                        v-for="(item,i) in betList" :key="i"
                        class="bet-coin-item"
                        :class="{active:currentSelBet === item}" @click="clickBet(item)"
                        :src="getBetImageSrc(item)"
                        alt="">
                </div>
                <img @click="opt(1)" class="coin-set" src="../assets/room/coin-set.png" alt="">
            </div>
            <img @click="opt(3)" class="arr-l arr" src="../assets/room/sure.png" alt="">
        </div>
        <!--限红 局号，期号-->
        <div class="box-info">
            <!--<div @click="del(3)">限红</div>-->
            <div style="text-align: left">期号 {{ periods }}</div>
            <!--            <Button style="z-index: 99" type="primary" @click="dialogVisible = true">哈希验证</Button>-->
            <div style="text-align: right">本局投注{{ total }}</div>
        </div>
        <div class="lu-tu">
            <Lu :luList="luList.slice(-6)" ref="lu" :historyList="historyList"></Lu>
        </div>
        <div class="bottom"></div>
        <Modal
            v-model="dialogVisible"
            title="提示：此处验证结果只提供参考"
            ok-text="验证"
            :mask-closable="false">
            <Input v-model="verify2" placeholder="清输入"></Input>
            <Input v-model="verify1" type="textarea" placeholder="请输入"></Input>
            <div style="display: flex;width: 100%;justify-content: space-evenly;padding:10px 0;">
                <div v-if="beginVerify">{{ isVerify ? '✅' : '❌' }}</div>
                <Button @click="handleCancel">取消</Button>
                <Button @click="handleOk" type="primary">验证</Button>
            </div>
        </Modal>
        <Modal
            v-model="visibleBet"
            title="提示：最多可选择5个筹码"
            ok-text="确认"
            :mask-closable="false">
            <div class="box-list">
                <div style="display: flex;flex-wrap: wrap">
                    <img
                        :class="{selImgCss:betSel.includes(item)}"
                        v-for="(item,i) in betListSel" :key="i"
                        style="width: 30px;margin: 10px"
                        @click="selBetFn(item)"
                        :src="getBetImageSrc(item)"
                        alt="">
                </div>
            </div>
            <div style="display: flex;width: 100%;justify-content: space-evenly;padding:10px 0;">
                <Button @click="visibleBet = false" style="border-radius: 6px">取消</Button>
                <Button @click="handleSel" type="primary" style="border-radius: 6px">选择</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import CryptoJS from 'crypto-js';
import myVideo from '../components/myVideo.vue'
import Lu from '../components/Lu.vue'
import countDown from 'vue-canvas-countdown'
// import {backgroundImage} from "html2canvas/dist/types/css/property-descriptors/background-image";

export default {
    name: 'gameTable',
    // computed: {
    //     backgroundImage() {
    //         return backgroundImage
    //     }
    // },
    components: {
        myVideo,
        countDown,
        Lu
    },
    data() {
        return {
            isFlipped: false,  //是否播放翻牌动画
            visibleBet: false,
            beginVerify: false,
            isVerify: false,  //哈希是否匹配
            verify1: '',
            verify2: '',
            dialogVisible: false,  //哈希校验对话框
            fakeUsers: 0,  //机器人人数
            loading: true,
            optDesc: 1800,  //用户一定时间不操作就会被踢到大厅
            optTimer: null, //用户操作时间
            ifCurrent: true, //是否在当前页面
            voice: 1,  //声音大小
            arrShow: 3, //1左  2两个 3右
            getSound: false,
            stage: 0,  //桌子状态 0开桌（基本不会有）  1下注 2停止下注 3结算
            canBet: true,  //防止重复
            lu: false,
            luList: [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []],
            historyList: [],
            read: [
                {n: '数字', v: '1:2.91', c: '投注单一号码，开出为赢，其余为输 如：压1，开1为赢，2、3、4为输'},
                {
                    n: '角',
                    v: '1:1.94',
                    c: '投注两个号码，一个为赢，一个为和，另外两个为输 如：压1带2，开1为赢，开2为和，3、4为输'
                },
                {
                    n: '连',
                    v: '1:0.97',
                    c: '投注两个号码，开出为赢，其余为输 如：1连2，开1、2为赢，3、4为输'
                },
                {
                    n: '保',
                    v: '1:0.95',
                    c: '例如压1保23，开1为赢 2，3退回本金 开4为输'
                },
                {n: '单', v: '1:0.97', c: '1、3为单'},
                {n: '双', v: '1:0.97', c: '2、4为双'},
            ],
            scale: 1,//放大镜
            modal1: false,
            modal2: false,
            modal3: false,
            modal4: false,
            win: 0,
            openR: 0,  //开奖结果
            hashR: '',  //哈希值开奖结果
            currentInfo: {},
            result: '',
            showWin: false,
            audio: null,
            audio1: null,  //背景音乐
            showTextCon: '',
            showText: false,
            modalText: '',
            showTimer: null,
            total: 0,
            elementLeft: 0,
            person: 1, //房间人数
            periods: 0,
            tableInfo: {},
            betTimer: null,
            firstCome: true,
            user: localStorage.getItem('userinfo') && JSON.parse(localStorage.getItem('userinfo')),
            ifConnect: 0,
            timer: null,
            desc: 60,
            moneybab: 0,
            limitConfig: null, //限红设置
            socket: null,
            opener: '',
            bedtime: 0,
            descTime: 0,
            descTimer: null,
            ws: 'wss://ws.tg86.org//websocket/',
            // ws: 'ws://101.32.31.213:9920/websocket/',
            lastBetInfo: {},
            ifCanBetAgain: false,   //是否可以续押
            betMoney: 0,
            txtList: [
                '1带2', '1带3', '1带4',
                '2带1', '2带3', '2带4',
                '3带1', '3带2', '3带4',
                '4带1', '4带2', '4带3',  //3 X  4
                "1/2角", "2/3角",
                "3/4角", "1/4角",  //2 X 2
                "单", "双",  //1 X  2
                "1保2/3", "1保2/4",
                "1保3/4", "2保1/3",
                "2保1/4", "2保3/4",
                "3保1/2", "3保1/4",
                "3保2/4", "4保1/2",
                "4保1/3", "4保2/3",//2 * 6
                "1", "2", "3", "4",  //1 X 4
            ],
            // betListWait1: [  //待确认的投注
            //     23,24,22,
            //     20,9,15,
            //     2, 3, 1,
            //     16,10,5,  //3 X  4
            //     25,4,
            //     0,21,   //2 X 2
            //     12,13,  //1 X  2
            //     26,34,
            //     30,25,
            //     31,27,
            //     32,28,
            //     36,29,
            //     37,33,  //2 * 6
            //     18,14,7,11,  //1 X 4
            // ],
            betListWait: [  //待确认的投注
                0, 0, 0,
                0, 0, 0,
                0, 0, 0,
                0, 0, 0,
                0, 0,
                0, 0,
                0, 0,
                0, 0,
                0, 0,
                0, 0,
                0, 0,
                0, 0,
                0, 0,
                0, 0, 0, 0,
            ],
            betListMySure: [  //这一局自己确认的投注
                0, 0, 0,
                0, 0, 0,
                0, 0, 0,
                0, 0, 0,
                0, 0,
                0, 0,
                0, 0,
                0, 0,
                0, 0,
                0, 0,
                0, 0,
                0, 0,
                0, 0,
                0, 0, 0, 0,
            ],
            betListSureOnce: [  //这一轮已确认的投注
                0, 0, 0,
                0, 0, 0,
                0, 0, 0,
                0, 0, 0,
                0, 0,
                0, 0,
                0, 0,
                0, 0,
                0, 0,
                0, 0,
                0, 0,
                0, 0,
                0, 0,
                0, 0, 0, 0,
            ],
            betListSure: [  //所有已经确认的投注
                0, 0, 0,
                0, 0, 0,
                0, 0, 0,
                0, 0, 0,
                0, 0,
                0, 0,
                0, 0,
                0, 0,
                0, 0,
                0, 0,
                0, 0,
                0, 0,
                0, 0,
                0, 0, 0, 0,
            ],
            // cyyList: [
            //     'C3', 'B5', 'B11', 'B4', 'C2',
            //     'B6', '--', 'A3', '--', 'B3',
            //     'B12', 'A4', 'D1', 'D2', 'A2', 'B10',
            //     'B7', '--', 'A1', '--', 'B2',
            //     'C4', 'B8', 'B1', 'B9', 'C1',
            //     'E1', "E6", "E8", "E10",
            //     'E3', "E5", "E7", "E12",
            //     'E2', "E4", "E9", "E11",
            // ],
            cyyList: [
                'B1', 'B9', 'B8',
                'B2', 'B3', 'B10',
                'B11', 'B4', 'B5',
                'B7', 'B12', 'B6',  //3 X  4
                "C1", "C2",
                "C3", "C4",  //2 X 2
                "D1", "D2",  //1 X  2
                "E1", "E2",
                "E3", "E4",
                "E5", "E6",
                "E7", "E8",
                "E9", "E10",
                "E11", "E12",//2 * 6
                "A1", "A2", "A3", "A4",  //1 X 4
            ],
            openBetList: [], //开奖结果列表
            betList: [10, 50, 100, 500, 1000],
            betSel: [],  //弹窗选择
            betListSel: [10, 20, 50, 100, 500, 1000, 5000, 10000, 20000, 50000],
            currentSelBet: 10, //当前选中的筹码
            windowWidth: document.documentElement.clientWidth,  //实时屏幕宽度
            windowHeight: document.documentElement.clientHeight,   //实时屏幕高度
            message: '',
            id: '',
            videoHeight: 0,
            source: "",
            // source: "http://154.207.104.4:8080/live/SMcaiyuanguangjin888.m3u8",
            // source: "http://154.207.104.4:8080/players/rtc_player.html?vhost=__defaultVhost__&app=live&stream=SMcaiyuanguangjin888.flv&server=154.207.104.4&port=8080&autostart=true&schema=http",
            options: {
                isLive: true, // 是否开启直播
                language: "zh-custom", // 自定义语言包
                languageTexts: {
                    // 播放器自定义提示文字
                    "zh-custom": {
                        Video_Error: "视频播放异常", // 视频播放异常
                        Error_Load_M3U8_Timeout_Text: "获取m3u8文件超时", // 获取m3u8文件超时
                        Error_Load_M3U8_Failed_Text: "获取m3u8文件失败", //  获取m3u8文件失败
                        Live_Abrot: "直播信号中断", // 直播信号中断
                        Error_Not_Found: "播放地址不存在", // 播放地址不存在
                        Refresh_Text: "刷新",
                        Retry: "重试",
                        Detection_Text: "诊断"
                    }
                }
            }
        }
    },
    created() {
        this.getMember()
        // this.getTables(1)
        this.id = this.$route.query.gpage
        this.opener = this.$route.query.page1
        this.source = this.$route.query.pp
        // this.bedtime = this.$route.query.pp1
        // this.periods = this.$route.query.pp2
        this.playSound('bg.mp3')
        this.calcVideoHeight()
    },
    mounted() {
        document.addEventListener('visibilitychange', this.handleVisibilityChange, false);
        // this.addWheelEventListener()
        // 创建防抖后的点击处理函数
        this.sureBet = this.debounce(this.sureBet, 300); // 这里的500是延迟时间，单位是毫秒
    },
    watch: {
        betListSure(newVal,oldVal) {
            console.log('---------betListSure-----------')
            console.log( newVal )
        },
        // stage(newVal, old) {  //桌面状态
        //     console.log('stage----桌面状态', newVal)
        // },
        // loading(newVal,old){
        //     if(!newVal){
        //         console.log( 1234 )
        //         if(this.descTimer) clearInterval(this.descTimer)
        //         this.getTables(1)
        //     }
        // }
        dialogVisible(newVal, oldVal) {
            if (newVal) {
                this.verify1 = this.currentInfo.has
                this.verify2 = this.hashR
            } else {
                this.beginVerify = false
            }
        },
        loading: {
            handler(newVal, old) {
                if (!newVal) {
                    if (this.descTimer) clearInterval(this.descTimer)
                    this.getTables(1)
                } else {
                    this.reConnect()
                }
            },
            // immediate: true
        },
        openR: {
            handler(newVal, oldVal) {
                console.log(newVal)
                this.isFlipped = newVal != 0;
            }
        }
    },
    methods: {
        addWheelEventListener() {
            window.addEventListener('touchmove', this.wheelEventHandler, {passive: false});
        },
        removeWheelEventListener() {
            window.removeEventListener('touchmove', this.wheelEventHandler,);
        },
        handleVisibilityChange() {
            if (document.visibilityState === 'visible') {
                // 页面从后台切换到前台
                location.reload(); // 刷新页面
            }
            if (document.hidden) {
                // 页面切换到后台，暂停音频播放
                this.ifCurrent = false
            } else {
                // 页面回到前台，继续音频播放
                this.ifCurrent = true
            }
            this.playSound('bg.mp3')
        },
        wheelEventHandler(event) {
            event.preventDefault();
        },
        selBetFn(item) {  //弹窗的筹码选择
            this.betSel = this.toggleElement(this.betSel, item)
        },
        toggleElement(arr, i) {
            const index = arr.indexOf(i);
            if (index > -1) {
                arr.splice(index, 1); // 删除元素
            } else {
                if (this.betSel.length >= 5) return arr
                arr.push(i); // 添加元素
            }
            arr.sort((a, b) => a - b); // 重新排序
            return arr;
        },
        handleOk() {
            console.log('开始验证')
            this.calculateSHA256()
        },
        handleSel() {
            // 确认更换筹码
            this.betList = [].concat(this.betSel)
            this.visibleBet = false
            // this.betSel = this.toggleElement(this.betSel, true);
        },
        handleCancel() {
            this.dialogVisible = false;
        },
        calculateSHA256() {  //sha256 加密
            this.beginVerify = true
            let message = this.verify2
            if (this.verify2.includes("_")) message = this.verify2.split("_")[0]
            console.log(message)
            const expectedHash = this.verify1;
            const computedHash = CryptoJS.SHA256(message).toString(CryptoJS.enc.Hex); // 生成 SHA-256 哈希并转换为 Base64 编码
            // this.hashedValue = hashed;
            console.log("计算出的哈希:", computedHash);
            console.log("哈希是否匹配:", computedHash.toLowerCase() === expectedHash.toLowerCase());
            this.isVerify = computedHash.toLowerCase() === expectedHash.toLowerCase()
        },
        // 复制
        async handleCopy(con) {
            try {
                await navigator.clipboard.writeText(con);
                this.$Notice.info({
                    title: '复制成功',
                });
            } catch (err) {
                this.$Notice.warning({
                    title: '复制失败，请手动复制！',
                });
            }
        },
        triggerFlip() {
            setTimeout(() => {
                // 每次触发时，设置 isFlipped 为 true 以启动动画
                this.isFlipped = true;

                // 动画完成后，重置状态（如果需要重新触发动画）
                setTimeout(() => {
                }, 4000); // 这里的 4000 毫秒是动画的时间
            }, 4000)
        },
        /*进入桌面初始化*/
        getTables(type) {  //获取桌子
            this.historyList = []  //每次需要初始化
            this.$http.get(this.host + this.api.game.getTables).then(response => {
                let resp = response.body;
                if (resp.code == 200) {
                    this.loading = false
                    // 桌子当前状态
                    let tableId = this.$route.query.gpage
                    if (resp && resp.data[tableId] && type) {
                        let outId = this.ifOnTheOtherTable(resp.data)
                        if (outId) {
                            this.outTable(outId)
                            return
                        }
                        this.stage = resp.data[tableId].stage
                        this.tableInfo = resp.data[tableId]
                        let countDown = resp.data[tableId].countDown
                        this.bedtime = resp.data[tableId].config && resp.data[tableId].config.betTime
                        let data = resp.data[tableId]
                        this.fakeUsers = resp.data[tableId].fakeUsers //robot
                        // 获取桌面筹码
                        if(this.stage != 3){  //如果是结束下注 则不处理初始化
                            this.dealSureBet(data)
                        }
                        if (this.stage == 0) {
                            // console.log('台桌开始')
                        }
                        if (this.stage == 1) {
                            // console.log('开始下注')
                            this.descTime = countDown
                            this.descBetTime(1)
                        }
                        if (this.stage == 2) {
                            // console.log('结束下注')
                        }
                        if (this.stage == 3) {
                            // console.log('结算')
                            if (localStorage.getItem('myBetInfo' + this.id)) {
                                //已经结算就不需要展示我放下注
                                localStorage.removeItem('myBetInfo' + this.id)
                            }
                        }
                        //中途加入的展示  hash以及开奖
                        this.currentInfo.has = this.tableInfo.currentPeriod.has
                        this.hashR = this.tableInfo.currentPeriod.result
                    }
                    this.tableData = resp.data
                    if (resp && resp.data[tableId] && resp.data[tableId].historyPeriod) {  //路图
                        const current = resp.data[tableId].currentPeriod
                        // 初始化期数
                        if (Number(current.period)) { //有期数
                            this.periods = Number(current.period)
                        } else { //年 月  日 + 0
                            this.periods = this.calcInitPeriods()
                        }
                        //初始化 确认期数后  请求我方已经下注的信息展示
                        if (localStorage.getItem('myBetInfo' + this.id)) {
                            let tableId = this.$route.query.gpage
                            let t = JSON.parse(localStorage.getItem('myBetInfo' + this.id))
                            if (this.periods == t.periods && tableId == t.tableId) {  //期数与保存的一致并且tableId相同
                                this.betListMySure = JSON.parse(JSON.stringify(t.list))
                                this.betListSureOnce = JSON.parse(JSON.stringify(t.list))
                                JSON.parse(JSON.stringify(t.list)).forEach(item => {
                                    if (Number(item) > 0) {
                                        this.total += Number(item)
                                        this.lastBetInfo.total = this.total
                                    }
                                })
                            }

                        }
                        if (resp.data[tableId].historyPeriod.length) {
                            resp.data[tableId].historyPeriod.map(item => {
                                item.result = item.result.slice(-1)
                            })
                        }
                        let jon = resp.data[tableId].historyPeriod
                        for (let k in jon) {
                            this.historyList.push(jon[k])
                        }
                        this.dealLu()
                    }
                }
            });
        },
        /*重新请求桌子信息*/
        getTablesAgain() {  //获取桌子
            // this.historyList = []  //每次需要初始化
            this.$http.get(this.host + this.api.game.getTables).then(response => {
                let resp = response.body;
                if (resp.code == 200) {
                    let tableId = this.$route.query.gpage
                    this.tableData = resp.data
                    if (resp && resp.data[tableId] && resp.data[tableId].historyPeriod) {  //路图
                        if (resp.data[tableId].historyPeriod.length) {
                            resp.data[tableId].historyPeriod.map(item => {
                                item.result = item.result.slice(-1)
                            })
                        }
                        let jon = resp.data[tableId].historyPeriod
                        let t = jon.slice(-1)[0]
                        this.historyList.push(t)
                    }
                }
            });
        },
        //开奖呼吸效果样式绑定
        getClassNames(l) {
            return {
                ['col' + l]: true,
                ['col-s']: this.openBetList.includes('col' + l),
            };
        },
        // 判断是不是在其他的桌子里面
        ifOnTheOtherTable(data) {
            let id = null
            for (let key in data) {
                if (this.id != key) {
                    if (data[key].users.length) {
                        let userid = this.user && this.user.userNo
                        if (data[key].users.indexOf(userid) > 0) {
                            id = key
                        }
                    }
                }
            }
            return id
        },
        calcInitPeriods() {  //没有期数自己设定 年+月+日+0  24+06+12+0  2406120
            let y = String(new Date().getFullYear())
            let m = new Date().getMonth() + 1
            let d = new Date().getDate()
            // console.log(y, m, d)
            m = m < 10 ? '0' + m : m
            d = d < 10 ? '0' + d : d
            return `${y.slice(-2)}${m}${d}0`
        },
        getMember() {//获取个人安全信息  主要需要知道余额
            let self = this
            this.$http
                .post(self.host + this.api.uc.personalWallet)
                .then(response => {
                    let resp = response.body;
                    if (resp.code == "0000") {
                        self.moneyList = resp.data
                        self.moneyList.forEach(element => {
                            if (element.ccy == 'USDT') {
                                self.moneybab = element
                                self.ws = self.ws + element.userNo
                                // 初次进来需要初始化桌子 连接socket   后续只是刷新余额
                                if (self.firstCome) {
                                    this.jsonTable(element.uid) // 加入桌子
                                    self.connectSocket()
                                    self.firstCome = false
                                }
                            }
                        });
                    } else {
                        this.$Notice.error({
                            title: this.$t("common.tip"),
                            desc: resp.mesg
                        });
                    }
                });
        },
        jsonTable(userId) { //加入桌子
            let params = {
                tableId: this.id,
                userId
            }
            this.$http.get(this.host + this.api.game.intoTable, {params}).then(response => {
                let resp = response.body;
                this.getGameConfig()
                if (resp.code == 200) {
                    this.showTextFn('成功加入桌子')
                } else {
                    if (resp.msg === '您已经在该桌子') {
                        this.showTextFn('成功加入桌子')
                    } else {
                        // this.$Message.error(resp.msg);
                        this.showModal(resp.msg)
                    }
                }
            });
        },
        //暂时取消拿配置
        getGameConfig() { //获取游戏配置
            let param = {}
            param.tableId = this.id
            this.$http.get(this.host + this.api.game.getConfig, {params: param}).then(response => {
                let resp = response.body;
                if (!response.ok) {
                    // this.$Message.error('获取游戏配置异常');
                    this.showModal('获取游戏配置异常')
                } else {
                    this.limitConfig = resp
                    // if (resp.betChips && resp.betChips.length) {
                    //     resp.betChips.forEach((item, i) => {
                    //         if ([10, 20, 50, 100, 500, 1000, 5000, 10000, 20000, 50000].includes(Number(item))) {
                    //             this.betList.push(Number(item))
                    //         }
                    //     })
                    // }
                }
            });
        },
        /*进入桌面初始化结束*/
        dealLu() {
            if (!this.historyList.length) return
            this.$nextTick(() => {
                this.$refs.lu.getList()
            })
        },
        calcVideoHeight() {
            this.videoHeight = 100 * 9 / 16 + 'vw'
        },
        /*计时器相关事件*/
        userOptFun() {
            let desc = this.optDesc
            this.optTimer = setInterval(() => {
                desc--
                if (desc <= 0) {
                    this.$router.push({path: '/about'})
                }
            }, 1000)
        },
        onStatusChange(payload) {
            let t = null
            let num = 5
            if (payload == 3) { //还有5秒的提示
                t = setInterval(() => {
                    num--
                    this.playSound('desc.mp3')
                    if (num < 1) clearInterval(t)
                }, 1000)
            }
            if (payload == 0) {  //结束
                clearInterval(t)
            }
            // console.log('倒计时状态改变：', payload)
        },
        descBetTime(type = 1) {  //1开始  2结束
            if (type === 1) {
                this.descTimer = setInterval(() => {
                    if (this.descTime > 0) this.descTime--
                    if (this.descTime === 5) {  //还有5秒 提醒倒计时
                        this.playSound('warn.mp3')
                        this.onStatusChange(3)
                    }
                    if (this.descTime < 1) {
                        this.stage = 2  //前端时间先到停止下注阶段
                        this.onStatusChange(0)
                        clearInterval(this.descTimer)
                    }

                }, 1000)
            }
            if (type === 2) {
                this.stage = 2
                this.lastBetInfo.total = this.total //记录自己当前次数下注总额 计算输赢
            }
        },
        /*倒计时相关事件结束*/
        /*socket*/
        connectSocket() {
            const _this = this
            this.socket = new WebSocket(this.ws);
            // 连接建立时触发
            this.socket.addEventListener('open', function (event) {
                console.log('socket 连接成功!');
                _this.ifConnect = true
                _this.userOptFun()
                _this.pingFun()
                _this.loading = false
            });
            // 接收到消息时触发
            this.socket.addEventListener('message', function (event) {
                if (event.data == 1) _this.ifConnect = true
                if (event.data && JSON.parse(event.data)) {  //推送会推所有房间的 屏蔽其他房间信息
                    let d = JSON.parse(event.data)
                    if (d.tableId != _this.id) return
                }
                if (event.data.includes('startBet') || event.data.includes('endBet') || event.data.includes('settlement')) {  //开始下注和结束下注信息
                    if (event.data.includes('startBet')) {
                        _this.getStatusByOs(1)
                        _this.hashR = ''  //清空当前的hashR的值
                        _this.currentInfo = JSON.parse(event.data).table.currentPeriod
                    }
                    if (event.data.includes('endBet')) {
                        _this.getStatusByOs(2)
                    }
                    if (event.data.includes('settlement')) { //结果推送
                        let t = JSON.parse(event.data) && JSON.parse(event.data).result
                        _this.hashR = t
                        _this.getStatusByOs(3, t.slice(-1))
                    }
                } else if (typeof event.data === 'string') {
                    if (event.data.includes('betTotal')) {  //下注
                        let t = JSON.parse(event.data)
                        _this.dealSureBet(t)
                    } else if (event.data.includes('users')) { //获取用户
                        let t = JSON.parse(event.data)
                        _this.dealSureBet(t, 2)
                    } else if (event.data.includes('result')) {
                        // _this.dealSureBet(t, 3)
                        // _this.dealSureBet(JSON.parse(JSON.stringify(event.data)), 1)
                    } else if (event.data.includes('closeTable')) {
                        console.log('牌桌关闭')
                        _this.$router.push({path: '/about'})
                    }
                }
            });
            // 发生错误时触发
            this.socket.addEventListener('error', function (event) {
                // _this.outTable()
                console.error('WebSocket error:', event);
            });
        },
        pingFun() {
            if (this.timer) { /// 如果有已有的计时器，先清除它
                clearInterval(this.timer);
            }
            this.timer = setInterval(() => {
                if (this.ifConnect) {
                    console.log('心跳正常')
                    this.ifConnect = false
                    if (this.socket) this.socket.send('0');
                } else {
                    this.del(5)
                    console.log('socket已断开，正在重新连接')
                    if (this.socket) this.socket.close()
                    this.loading = true
                    this.connectSocket()
                }
            }, 5000)
        },
        getStatusByOs(n, t) { //服务器拿回的状态 1 2 3
            if (n === 1) {
                this.canBet = true
                this.modal4 = false
                this.stage = 1
                this.openR = 0 //开奖区域重置
                this.descTime = this.bedtime
                this.win = 0
                this.openR = 0
                if (this.modal4) this.modal4 = false
                this.total = 0
                if (localStorage.getItem('myBetInfo' + this.id)) {
                    localStorage.removeItem('myBetInfo' + this.id)
                }
                this.descBetTime(1)
                this.resetWaitSet(3)
                this.showTextFn('已开局 请下注', 2)
                this.playSound('start.mp3')
                this.getTables()

            }
            if (n === 2) {
                this.stage = 2
                this.resetWaitSet(1)
                this.showTextFn('停止下注', 3)
                this.playSound('stop.mp3')
                this.descBetTime(2)
                clearInterval(this.descTimer)
            }
            if (n === 3) {
                this.stage = 3
                this.resetWaitSet()
                this.resetWaitSet(2)
                // this.resetWaitSet(3)
                this.resetWaitSet(4)
                this.playSound('result.mp3')
                this.showResult(t)
                this.descBetTime(2)
                clearInterval(this.descTimer)
            }
        },
        reConnect() {  //重连初始化的事件
            this.win = 0
            this.openR = 0
            this.total = 0
            this.resetWaitSet(3)
            this.resetWaitSet()
            this.resetWaitSet(2)
            this.resetWaitSet(4)
            this.del(5)  //短线 所有弹窗关闭
        },
        /*筹码计算*/
        // 0-14
        // 1-8  11-33
        // 2-6  12-16
        // 3-7  13-17
        // 4-13 14-31
        // 5-11 15-5
        // 6---- 16-9
        // 7-32   17-----
        // 8-----   18-30
        // 9-4     19-----
        // 10-10    20-3

        // 21-15  31-22
        // 22-2   32-24
        // 23-0   33-29
        // 24-1   34-19
        // 25-12  35-21
        // 26-18  36-26
        // 26-18  37-28
        // 27-23
        // 28-25
        // 29-27
        // 30-20

        calcResult(r) {
            let mon = 0
            if (r == 1) {
                this.betListSureOnce.forEach((item, i) => {
                    let tax = this.getTax(i)
                    if (i === 30) mon += Number(item) * 3 * tax + Number(item)
                    if (i === 0 || i === 2 || i === 1) mon += Number(item) * 2 * tax + +Number(item)
                    if (i === 3 || i === 9 || i === 6 || [22, 21, 25, 24, 27, 28].includes(i)) mon += Number(item)
                    if (i === 12 || i === 16 || i === 15 || [18, 20, 19].includes(i)) mon += Number(item) * tax + Number(item)
                    // [0, 1, 2, 3, 6, 9, 12, 15, 16, 18, 19, 20, 21, 22, 24, 25, 27, 28, 30]
                })
            }
            if (r == 2) {
                this.betListSureOnce.forEach((item, i) => {
                    let tax = this.getTax(i)
                    if (i === 31) mon += Number(item) * 3 * tax + Number(item)
                    if (i === 3 || i === 4 || i === 5) mon += Number(item) * 2 * tax + Number(item)
                    if (i === 0 || i === 7 || i === 10 || [18, 19, 24, 26, 27, 29].includes(i)) mon += Number(item)
                    if (i === 12 || i === 13 || i === 17 || [23, 22, 21].includes(i)) mon += Number(item) * tax + Number(item)
                    // [0, 3, 4, 5, 7, 10, 12, 13, 17, 18, 19, 21, 22, 23, 24, 26, 27, 29, 31]
                })
            }
            if (r == 3) {
                this.betListSureOnce.forEach((item, i) => {
                    let tax = this.getTax(i)
                    if (i === 32) mon += Number(item) * 3 * tax + Number(item)
                    if (i === 7 || i === 8 || i === 6) mon += Number(item) * 2 * tax + Number(item)
                    if (i === 4 || i === 11 || i === 1 || [18, 20, 23, 21, 29, 28].includes(i)) mon += Number(item)
                    if (i === 14 || i === 13 || i === 16 || [25, 24, 26].includes(i)) mon += Number(item) * tax + Number(item)
                    // [1, 4, 6, 7, 8, 11, 13, 14, 16, 18, 20, 21, 23, 24, 25, 26, 28, 29, 32]
                })
            }
            if (r == 4) {
                this.betListSureOnce.forEach((item, i) => {
                    let tax = this.getTax(i)
                    if (i === 33) mon += Number(item) * 3 * tax + Number(item)
                    if (i === 11 || i === 9 || i === 10) mon += Number(item) * 2 * tax + Number(item)
                    if (i === 8 || i === 2 || i === 5 || [20, 19, 23, 22, 25, 26].includes(i)) mon += Number(item)
                    if (i === 14 || i === 15 || i === 17 || [27, 29, 28].includes(i)) mon += Number(item) * tax + Number(item)
                })
                // [2, 5, 7, 9, 10, 11, 14, 15, 17, 19, 20, 22, 23, 25, 26, 27, 28, 29, 33]
            }
            // 开奖结果的特效
            mon = mon - this.lastBetInfo.total
            // debugger
            return this.formatNumber(Number(mon))
        },
        formatNumber(num) {  //输赢结果保留1位小数
            // 判断是否带有小数点
            if (num % 1 !== 0) {
                // 四舍五入并保留1位小数
                return Math.round(num * 10) / 10;
            } else {
                // 如果是整数，则直接返回
                return num;
            }
        },
        /*下注新增参数 需计算*/
        calcBetTotal(type = 1) {
            let total = {1: 0, 2: 0, 3: 0, 4: 0}
            let oddsTotal = {1: 0, 2: 0, 3: 0, 4: 0}
            this.betListWait.forEach((item, i) => {
                if (item > 0 && [18, 23, 22, 24, 25, 12, 21, 26, 30, 34, 20, 16, 2, 31, 35, 28, 32, 29, 37].includes(i)) {
                    // if(![31, 35, 28, 32, 29, 37].includes(i))  total[1] += item
                    if (i === 30) oddsTotal[1] += Number(item) * 4
                    if (i === 0 || i === 2 || i === 1) oddsTotal[1] += Number(item) * 3
                    if (i === 3 || i === 9 || i === 6 || [22, 21, 25, 24, 27, 28].includes(i)) oddsTotal[1] += Number(item)
                    if (i === 12 || i === 16 || i === 15 || [18, 20, 19].includes(i)) oddsTotal[1] += Number(item) * 2
                }
                if (item > 0 && [14, 20, 9, 15, 25, 4, 13, 27, 31, 35, 23, 3, 10, 26, 34, 32, 36, 29, 33].includes(i)) {
                    // if(![26, 34, 32, 36, 29, 33].includes(i))  total[2] += item
                    if (i === 31) oddsTotal[2] += Number(item) * 4
                    if (i === 3 || i === 4 || i === 5) oddsTotal[2] += Number(item) * 3
                    if (i === 0 || i === 7 || i === 10 || [18, 19, 24, 26, 27, 29].includes(i)) oddsTotal[2] += Number(item)
                    if (i === 12 || i === 13 || i === 17 || [23, 22, 21].includes(i)) oddsTotal[2] += Number(item) * 2
                }
                if (item > 0 && [7, 3, 1, 2, 0, 4, 12, 28, 32, 36, 9, 5, 24, 26, 30, 27, 35, 33, 37].includes(i)) {
                    // if(![26, 30, 27, 35, 33, 37].includes(i))  total[3] += item
                    if (i === 32) oddsTotal[3] += Number(item) * 4
                    if (i === 7 || i === 8 || i === 6) oddsTotal[3] += Number(item) * 3
                    if (i === 4 || i === 11 || i === 1 || [18, 20, 23, 21, 29, 28].includes(i)) oddsTotal[3] += Number(item)
                    if (i === 14 || i === 13 || i === 16 || [25, 24, 26].includes(i)) oddsTotal[3] += Number(item) * 2
                }
                if (item > 0 && [11, 5, 16, 10, 0, 21, 13, 29, 33, 37, 1, 22, 15, 30, 34, 27, 31, 28, 36].includes(i)) {
                    // if(![30, 34, 27, 31, 28, 36].includes(i))  total[4] += item
                    if (i === 33) oddsTotal[4] += Number(item) * 4
                    if (i === 11 || i === 9 || i === 10) oddsTotal[4] += Number(item) * 3
                    if (i === 7 || i === 2 || i === 5 || [20, 19, 23, 22, 25, 26].includes(i)) oddsTotal[4] += Number(item)
                    if (i === 14 || i === 15 || i === 17 || [27, 29, 28].includes(i)) oddsTotal[4] += Number(item) * 2
                }
            })
            return type === 1 ? total : oddsTotal
        },
        // 区域赔率
        getTax(index) {
            if (!this.tableInfo.config.tax) return
            let tax
            if (this.cyyList[index].includes('A')) tax = this.tableInfo.config.tax['A']
            if (this.cyyList[index].includes('B')) tax = this.tableInfo.config.tax['B']
            if (this.cyyList[index].includes('C')) tax = this.tableInfo.config.tax['C']
            if (this.cyyList[index].includes('D')) tax = this.tableInfo.config.tax['D']
            if (this.cyyList[index].includes('E')) tax = this.tableInfo.config.tax['E']
            return (100 - Number(tax)) / 100  //佣金
        },
        resetWaitSet(type = 1) {
            if (type === 1) {
                this.betListWait.forEach((item, i) => {
                    this.$set(this.betListWait, i, 0)
                })
            }
            if (type === 2) {
                this.betListSure.forEach((item, i) => {
                    this.$set(this.betListSure, i, 0)
                })
            }
            if (type === 3) {
                this.betListSureOnce.forEach((item, i) => {
                    // if (localStorage.getItem('myBetInfo' + this.id)) {
                    //     localStorage.removeItem('myBetInfo' + this.id)
                    // }
                    this.$set(this.betListSureOnce, i, 0)
                })
            }
            if (type === 4) {
                this.betListMySure.forEach((item, i) => {
                    this.$set(this.betListMySure, i, 0)
                })
            }
        },
        /*弹窗*/
        showTextFn(c, t = 1.5, type, r) { //1  2展示输赢
            this.showText = true
            this.showTextCon = c
            this.showTimer = setTimeout(() => {
                this.showText = false
                this.showWin = false
                clearTimeout(this.showTimer)
            }, t * 1000)
        },
        showModal(c, t = 1.5) { //1  2展示输赢
            this.modal1 = true
            this.modalText = c
            let timer = setTimeout(() => {
                this.modal1 = false
                clearTimeout(timer)
            }, t * 1000)
        },
        del(n) {
            if (n === 1) {
                this.modal1 = !this.modal1
            }
            if (n === 2) {
                this.modal2 = !this.modal2
            }
            if (n === 3) {
                this.modal3 = !this.modal3
            }
            if (n === 4) {
                this.modal4 = !this.modal4
            }
            if (n === 5) {
                this.modal1 = false
                this.modal2 = false
                this.modal3 = false
                this.modal4 = false
            }
        },
        showResult(t) {
            this.triggerFlip()  //开奖动画
            this.win = this.calcResult(t)
            this.openR = t
            this.addClass(t)  //亮区
            // this.historyList.push({result: t})
            this.getTablesAgain()
            this.modal4 = true
            this.dealLu()
            // this.periods = Number(this.periods) + 1  //请求桌子信息即可
            this.playSound(t + '.mp3')
            this.getMember()
        },
        /*用户操作*/
        changeVoice() {
            if (this.voice === 0) {
                this.voice = 1
            } else {
                this.voice = 0
            }
            this.playSound('bg.mp3')
        },
        tableMove(d) {
            this.$nextTick(() => {
                let table = this.$refs.tableScroll
                if (d === 'l') {
                    table.scrollLeft = 0;
                }
                if (d === 'r') {
                    table.scrollLeft = table.scrollWidth - table.clientWidth
                }
            })
        },
        addClass(r) {
            this.openBetList = []
            if (r == 1) {
                this.openBetList = [
                    'col0', 'col1', 'col2', 'col3', 'col6', 'col9', 'col12', 'col15', 'col16', 'col18',
                    'col19', 'col20', 'col21', 'col22', 'col24', 'col25', 'col27', 'col28', 'col30',
                ]
            }
            if (r == 2) {
                this.openBetList = [
                    "col0", "col3", "col4", "col5", "col7", "col10", "col12", "col13", "col17", "col18",
                    "col19", "col21", "col22", "col23", "col24", "col26", "col27", "col29", "col31"
                ]
            }
            if (r == 3) {
                this.openBetList = [
                    "col1", "col4", "col6", "col7", "col8", "col11", "col13", "col14", "col16", "col18",
                    "col20", "col21", "col23", "col24", "col25", "col26", "col28", "col29", "col32"
                ]
            }
            if (r == 4) {
                this.openBetList = [
                    "col2", "col5", "col8", "col9", "col10", "col11", "col14", "col15", "col17", "col19",
                    "col20", "col22", "col23", "col25", "col26", "col27", "col28", "col29", "col33"
                ]
            }
            let timer = setTimeout(() => {
                this.openBetList = []
                clearTimeout(timer)
            }, 8000)
        },
        dealSureBet(data, type = 1) {
            if (data.fakeBetTotal) {
                data.betTotal = Object.assign(data.betTotal, data.fakeBetTotal)
            }
            let betTotal = data.betTotal
            let arr = []
            for (let k in betTotal) {
                arr.push({[k]: betTotal[k]})
            }
            if (type === 2) { //type = 2  统计房间人数
                this.tableInfo.p = data.users.length
                this.person = data.users.length
                this.fakeUsers = data.fakeUsers  //robot
                return
            }
            this.cyyList.forEach((item, m) => {
                arr.forEach((tem, n) => {
                    if (item == Object.keys(tem)[0]) {
                        let r = tem[Object.keys(tem)[0]]
                        this.$set(this.betListSure, m, r)
                    }
                })
            })
        },
        opt(type) {
            if (type === 1) {
                // this.playSound('lr.mp3')
                // this.lu = !this.lu
                // this.modal2 = false

                // 筹码选择
                this.visibleBet = true
            }
            if (type === 2) {
                this.playSound('lr.mp3')
                this.modal2 = !this.modal2
                this.lu = false
            }
            if (type === 3) {
                if (this.stage != 1) return
                this.playSound('sure_cancel.mp3')
                this.sureBet()
            }
            if (type === 4) {
                this.playSound('sure_cancel.mp3')
                this.betAgainOpt()

            }
            if (type === 5) {
                this.playSound('sure_cancel.mp3')
                this.resetWaitSet()
            }
        },
        sureBet() {
            clearInterval((this.optTimer))
            this.userOptFun()  //清除用户操作倒计时 重新开始
            this.canBet = false
            let betAmount = 0, betAreaTotal = {A: 0, B: 0, C: 0, D: 0, E: 0}, betArea = {}
            // let betNumTotal = this.calcBetTotal(1)
            let betNumOddTotal = this.calcBetTotal(2)
            this.betListWait.forEach((item, i) => {
                if (item > 0) {
                    betAmount += item
                }
                if (item > 0) {
                    let k = this.cyyList[i]
                    betArea[k] = item
                }
            })
            if (!betAmount > 0) return
            for (let key in betArea) {
                if (key.includes('A')) betAreaTotal['A'] += betArea[key]
                if (key.includes('B')) betAreaTotal['B'] += betArea[key]
                if (key.includes('C')) betAreaTotal['C'] += betArea[key]
                if (key.includes('D')) betAreaTotal['D'] += betArea[key]
                if (key.includes('E')) betAreaTotal['E'] += betArea[key]
            }
            let data = {
                betAmount,
                betAreaTotal,
                betArea,
                ccy: "USDT",
                tableId: this.id,
                // betNumTotal,
                betNumOddTotal
            }
            this.lastBetInfo = {
                betArea,
            }
            this.$http.post(this.host + this.api.game.bet, data).then(response => {
                let resp = response.body
                this.canBet = true
                if (resp.code == 200) {
                    this.betListWait.forEach((item, i) => {
                        if (item > 0) {
                            this.$set(this.betListMySure, i, this.betListMySure[i] + item)
                        }
                    })
                    let myBetInfo = {
                        list: this.betListMySure,
                        periods: this.periods,
                        tableId: this.$route.query.gpage
                    }
                    localStorage.setItem('myBetInfo' + this.id, JSON.stringify(myBetInfo))
                    this.$Message.success('下注成功');
                    this.playSound('move.mp3')
                    this.total += betAmount
                    // 下注成功记录到当前这一轮中
                    this.dealBetSure(betArea)
                    this.ifCanBetAgain = true
                    // 重置待确认bet
                    this.resetWaitSet()
                    // 下注成功 刷新余额
                    this.getMember()
                } else {
                    this.resetWaitSet()
                    if (resp.msg) this.showModal(resp.msg, 2)
                }
            })
        },
        dealBetSure(data) {
            this.cyyList.forEach((item, i) => {
                for (let k in data) {
                    if (k === item) {
                        this.betListSureOnce[i] += data[k]
                    }
                }
            })
        },
        clickBet(item) {
            console.log('---------item-----------')
            console.log(item)
            this.playSound('bet.mp3')
            this.currentSelBet = item
        },
        betBegin(l) {
            if (this.stage != 1) {  //先判断状态  不可下注
                this.$Message.warning('当前不在下注时间')
                return;
            }
            if (!this.betLimitOpt(l)) return;  // 下注限制判断
            this.playSound('bet.mp3')
            this.$set(this.betListWait, l, this.betListWait[l] + this.currentSelBet)
        },
        betAgainOpt() {  //续压按钮
            if (this.stage != 1) {  //先判断状态
                return;
            }
            // 清空现在可能已经存在的
            this.resetWaitSet()
            this.cyyList.forEach((item, i) => {
                for (let k in this.lastBetInfo.betArea) {
                    if (item === k) {
                        this.$set(this.betListWait, i, this.lastBetInfo.betArea[k])
                    }
                }
            })
        },
        /*其他*/
        outTable(id) { //离开桌子
            let params = {
                tableId: id || this.id,
                userId: this.moneybab.uid
            }
            this.$http.get(this.host + this.api.game.quitTable, {params}).then(response => {
                let resp = response.body;
                if (resp.code == 200) {
                    if (id) {
                        this.getTables(1)
                    }
                    // console.log('退出成功')
                    // if (this.$route.path !== '/GamePage') this.$router.push({path: '/GamePage'})
                } else {
                    // this.$Message.error(resp.msg);
                    this.showModal(resp.msg)
                }
            });
        },
        goBack() {
            //获取个人安全信息
            this.$router.back(-1)
        },
        getBetImageSrc(item) {
            return require(`../components/img/room/${item}.png`)
        },
        playSound(s) {
            if (this.voice === 0) {
                if (this.audio1) {
                    this.audio1.pause()
                    this.audio1 = null; // 清空 Audio 对象
                }
                return
            }
            if (!this.ifCurrent) {
                // console.log('false')
                if (this.audio1) {
                    this.audio1.pause()
                    this.audio1 = null; // 清空 Audio 对象
                }
                return
            }
            if (this.getSound) {
                if (this.audio1 && this.audio1.paused) {
                    this.audio1.play()
                }
                if (s == 'bg.mp3') {
                    let p1 = require("../assets/sound/" + s)
                    this.audio1 = new Audio(p1);
                    // 设置音频循环播放
                    this.audio1.loop = true;
                    this.audio1 && this.audio1.play();
                } else if (['start.mp3', 'stop.mp3', '1.mp3', '2.mp3', '3.mp3', '4.mp3'].includes(s)) {
                    if (s == 'start.mp3') this.$refs.audioTagStart.play()
                    if (s == 'stop.mp3') this.$refs.audioTagStop.play()
                    if (s == '1.mp3') this.$refs.audioTag1.play()
                    if (s == '2.mp3') this.$refs.audioTag2.play()
                    if (s == '3.mp3') this.$refs.audioTag3.play()
                    if (s == '4.mp3') this.$refs.audioTag4.play()
                } else {
                    let p = require("../assets/sound/" + s)
                    this.audio = new Audio(p); // 替换为你的 MP3 文件路径
                    this.audio.volume = 1
                    this.audio && this.audio.play();
                }
            } else {
                let p1 = require("../assets/sound/" + s)
                this.audio1 = new Audio(p1);
                this.audio1.loop = true;  // 设置音频循环播放
                this.audio1 && this.audio1.play();
                this.getSound = true
            }
        },
        betLimitOpt(l) { //r1 ,r2 ,r3  当前选择的筹码  个人当前下注总筹码  当前区域总筹码 + 当前自己要下注的
            let loc  //先获取地区 ABCD    // 先判断余额
            if (this.cyyList[l].includes('A')) loc = 'A'
            if (this.cyyList[l].includes('B')) loc = 'B'
            if (this.cyyList[l].includes('C')) loc = 'C'
            if (this.cyyList[l].includes('D')) loc = 'D'
            let total = 0
            this.betListWait.forEach(item => {
                if (item > 0) total += item
            })
            if (this.moneybab.amount < total) {
                this.showModal('余额不足')
                return false;
            }
            if (!this.limitConfig.limitBet) return true
            let r2 = Number(this.betListSureOnce[l]) + Number(this.currentSelBet)
            let r3 = Number(this.betListSure[l]) + Number(this.betListSureOnce[l]) + this.currentSelBet
            if (this.moneybab.amount < this.currentSelBet) {
                // this.$Message.warning('余额不足')
                this.showModal('余额不足')
                return false;
            } else if (r2 > this.limitConfig.limitBet[loc]) {  //个人限制
                // this.$Message.warning('当前区个人下注不可超过' + this.limitConfig.limitBet[loc])
                this.showModal('当前区个人下注不可超过' + this.limitConfig.limitBet[loc])
                return false;
            } else if (r3 > this.limitConfig.limitRed[loc]) {
                // this.$Message.warning('当前区总下注不可超过' + this.limitConfig.limitRed[loc])
                this.showModal('当前区总下注不可超过' + this.limitConfig.limitRed[loc])
                return false;
            } else {
                return true
            }
        },
        // 防抖函数
        debounce(func, delay) {
            let timer;
            return function () {
                const context = this;
                const args = arguments;
                clearTimeout(timer);
                timer = setTimeout(() => {
                    func.apply(context, args);
                }, delay);
            };
        },
    },
    beforeDestroy() {
        clearInterval(this.betTimer)
        clearInterval(this.timer)
        clearInterval(this.showTimer)
        clearInterval(this.descTimer)
        clearInterval(this.optTimer)
        this.outTable()
        // this.removeWheelEventListener()
        this.socket && this.socket.close();
        if (this.audio) {
            this.audio.pause();
            this.audio = null; // 清空 Audio 对象
        }
        if (this.audio1) {
            this.audio1.pause();
            this.audio1 = null; // 清空 Audio 对象
        }

    }
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.box-top {
    flex: 1;
    background-image: url("../assets/room/top-bg-nocover.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

/deep/ .ivu-modal {
    top: 35%;
}

/deep/ .ivu-modal-header {
    //display: none;
    height: 45px;
    //line-height: 45px;
    font-weight: bold;
    font-size: 16px;
    background-color: #2B967D;

    & > div {
        color: #fff !important;
    }
}

/deep/ .ivu-modal-content {
    .ivu-modal-close {
        //display: none;
    }
}

/deep/ .ivu-modal-body {
    padding: 0;

    .pop-box {
        background-color: #13171E;
        display: flex;
        flex-direction: column;

        .top {
            display: flex;
            height: 45px;
            line-height: 45px;
            position: relative;
            background: linear-gradient(to bottom, #3d4864, #262834);

            .t-l {
                width: 100%;
                text-align: center;
                color: #fff;
                font-size: 16px;
                font-weight: bold;
            }

            .t-r {
                font-size: 20px;
                line-height: 45px;
                position: absolute;
                right: 0;
                top: 0;
                background: linear-gradient(to bottom, #4b5f86, #2f374d);
                -webkit-clip-path: polygon(31% 0, 100% 0, 100% 100%, 0 100%);
                clip-path: polygon(31% 0, 100% 0, 100% 100%, 0 100%);
                height: 100%;
                width: 13%;
            }
        }

        .con {
            width: 96%;
            margin: 0 auto 20px;
            color: #fff;
            padding-bottom: 40px;
            border-bottom: 1px solid #fff;
            display: flex;
            flex-direction: column;

            .t-title {
                font-weight: bold;
                text-align: center;
                font-size: 18px;
                height: 30px;
                line-height: 30px;
            }

            .des {
                text-align: left;
                font-size: 12px;

                table {
                    width: 100%;
                    border-collapse: collapse;
                    box-sizing: border-box;

                    & > tbody > tr > td {
                        text-align: center;
                        box-sizing: border-box;
                        border: 1px solid #ddd;
                    }

                    thead {
                        tr {
                            th {
                                border: 1px solid #ddd;
                            }
                        }
                    }
                }
            }
        }
    }

    .pop-box-notice {
        margin: auto;

        .con {
            border: none;
            line-height: 100px;
            font-size: 16px;

            i {
                font-size: 20px;
            }
        }
    }
}

/deep/ .ivu-modal-footer {
    display: none;
}

/deep/ .ivu-modal-content {
    background-color: rgba(255, 255, 255, 0.6);
}

.model-notice {
    /deep/ .ivu-modal {
        width: 70% !important;
        margin: 100px auto;

        .ivu-modal-content {
            background-color: transparent;
        }

        .pop-box {
            background-color: transparent;
        }

        .con {
            color: #fff;
            background-color: rgba(17, 17, 1, .7);
            line-height: 90px;
            width: 100%;
        }

        .con1 {
            color: #fff;
            background-color: rgba(17, 17, 1, .7);
            line-height: 42px;
            height: auto;
            width: 100%;
            padding-bottom: 10px;

        }
    }
}

.box {
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
}

.nav-rights {
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    font-family: Arial, "Microsoft YaHei", "PingFang SC", "WenQuanYi Micro Hei", sans-serif;
    font-weight: bold;
    user-select: none;
    //max-height: 100vh; /* 最大高度为视口高度 */
    //overflow-y: auto; /* 根据内容高度自动显示滚动条 */
    //overscroll-behavior-y: none; /* 禁止垂直方向的橡皮筋效果 */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.popup-txt {
    position: fixed;
    top: 200px;
    left: 0;
    right: 0;
    margin: auto;
    width: 80%;
    height: 40px;
    z-index: 999;
    color: #fff;
    font-size: 14px;
    line-height: 40px;
    text-align: center;
    background-image: url("../components/img/room/popup_bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
}

.popup-txt-win {
    top: 150px;
    position: static;
}

.box_21 {
    height: 50px;
    padding: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 200;

    .back-img {
        width: 33.3%;
        display: flex;
    }

    .regular {
        margin: 0 20px;
        border-radius: 50%;
        width: 16px;
        float: right;
    }

    .title {
        color: aliceblue;
        font-size: 14px;
        text-align: center;
        line-height: 30px;
        width: 33.3%;
    }

    .balance {
        font-weight: lighter;
        display: flex;
        border-radius: 20px;
        font-size: 12px;
        padding: 0 10px;
        text-align: right;
        height: 20px;
        line-height: 20px;
        justify-content: end;
        align-items: center;
        background-color: #0B4447;
        color: #FFD576;

        & > img {
            height: 14px;
            //width: 16px;
            margin: 6px 2px;
        }
    }
}

.box-video {
    width: 100vw;
    height: calc(100% - 50px);
    position: relative;


    .cover {
        z-index: 99;
        position: absolute;
        left: 0;
        right: 0;
        top: -30%;
        bottom: 0;
        margin: auto;
        width: 26vw;
        height: 33vw;
        background: url("../assets/room/cover.png") no-repeat;
        background-size: 100% 100%;
        transform-origin: center; /* 让元素围绕自身的Y轴进行旋转 */
    }

    .flipAnimatioCover {
        animation: flipAnimation 4s forwards; /* 设置旋转动画 */
    }

    .cover1 {
        background-repeat: no-repeat;
        background-size: 100% 100%;
        transform-origin: center; /* 让元素围绕自身的Y轴进行旋转 */
        opacity: 0;
        transform: rotateY(180deg); /* 完全旋转 */
    }

    .flipAnimatioCover1 {
        animation: flipAnimation1 4s forwards; /* 设置旋转动画 */
    }

    @keyframes flipAnimation {
        0% {
            transform: rotateY(0deg); /* 初始状态，未旋转 */
            opacity: 1;
        }
        50% {
            transform: rotateY(90deg); /* 初始状态，未旋转 */
            opacity: 1;
        }
        51% {
            transform: rotateY(90deg); /* 初始状态，未旋转 */
            opacity: 0;
        }
        99% {
            transform: rotateY(180deg); /* 完全旋转 */
            opacity: 0;
        }
        100% {
            transform: rotateY(180deg); /* 完全旋转 */
            opacity: 1;
        }
    }
    @keyframes flipAnimation1 {
        0% {
            transform: rotateY(180deg); /* 初始状态，未旋转 */
            opacity: 0;
        }
        50% {
            transform: rotateY(270deg); /* 初始状态，未旋转 */
            opacity: 0;
        }
        51% {
            transform: rotateY(270deg); /* 初始状态，未旋转 */
            opacity: 1;
        }
        100% {
            transform: rotateY(360deg); /* 完全旋转 */
            opacity: 1;
        }
    }

    .icon-voice {
        position: absolute;
        bottom: 50px;
        left: 10px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        text-align: center;
        line-height: 20px;
        background-color: rgba(255, 255, 255, .3);
        z-index: 99;
    }

    .icon-voice-line:after {
        height: 2px;
        width: 100%;
        background-color: #ddd;
        content: '';
        position: absolute;
        top: 9px;
        left: 0;
        transform: rotate(45deg);
    }

    .icon-scale {
        position: absolute;
        top: 60px;
        left: 20px;
        z-index: 9999;
        width: 25px;
        height: 25px;
        //background-color: rgba(255, 255, 255, .5);
        border-radius: 50%;

        .icon-scale-icon {
            width: 24px;
            color: #3977C4;
            margin: 2px auto;
        }
    }


    .desc-popup {
        position: absolute;
        top: 10px;
        left: 10px;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        color: #fff;
        background-color: transparent;
        font-size: 10px;
        line-height: 45px;
        text-align: center;
    }

    .heguan, .result, .result2 {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 20px;
        color: #fff;
        font-size: 10px;
        line-height: 20px;
        text-align: left;
    }

    .result2 {
        line-height: 26px;
        font-size: 12px;
        width: 90%;
        height: 20px;
        bottom: 30px;
        text-align: left;
        text-indent: 0;
        left: 12px;
        overflow: auto;
        word-break: break-all; /* 允许长单词或哈希值换行 */
        overflow-wrap: break-word;
    }

    .result {
        width: 90%;
        left: 3%;
        height: 20px;
        line-height: 15px;
        font-size: 12px;
        bottom: 10px;
        overflow-wrap: break-word; /* 兼容性更好，确保单词换行 */
        white-space: normal; /* 正常换行 */
        word-break: break-all; /* 强制单词换行，适用于长单词 */
    }

    .person {
        position: absolute;
        top: 0px;
        right: 10px;
        //width: 200px;
        height: 20px;
        border-radius: 20px;
        padding: 0 6px;
        color: #fff;
        font-size: 12px;
        line-height: 30px;
        text-align: right;
        font-weight: 500;
        display: flex;
        justify-content: end;
        align-items: center;
        background-color: #0B4447;

        & > img {
            margin-right: 4px;
            height: 12px;
        }
    }
}

.box-info {
    background-color: #14413D;
    width: 100%;
    height: 30px;
    display: flex;
    line-height: 30px;
    color: #fff;
    font-size: 14px;
    justify-content: space-around;
    text-align: center;
    align-items: center;

    & > div {
        font-size: 12px;
        padding: 0 10px;
        width: 50%;
    }
}

.opencard::after {
    background: linear-gradient(#2D7D3E, #01D366, #2D7D3E);
    box-shadow: inset 0 0 0 2px #6BFF64;
    z-index: 0;
    top: 0;
    left: 0;
    position: absolute;
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    animation: portrait_opencard 1s infinite;
}

@-webkit-keyframes portrait_opencard {
    0% {
        opacity: 0;
    }

    50% {
        opacity: .8;
    }

    100% {
        opacity: 0;
    }
}

@keyframes portrait_opencard {
    0% {
        opacity: 0;
    }

    50% {
        opacity: .8;
    }

    100% {
        opacity: 0;
    }
}


onbet::before {
    display: none;
}


.bet-coin-list {
    height: 54px;
    display: flex;
    background-image: url("../assets/room/bet_bg.png");
    background-repeat: no-repeat;
    background-size: 140% 300%;
    background-position: center -75px;

    .arr {
        height: 54px;
        color: #fff;
        bottom: 4px;
    }

    .box-list {
        margin-top: 10px;
        width: 100%;
        height: 90%;
        position: relative;
        overflow: hidden;
        background: url("../assets/room/betlist.png") no-repeat;
        background-size: 100% 100%;
    }

    .bet-coin-list-box {
        padding: 4px 4px;
        position: absolute;
        overflow-x: auto;
        display: flex;
        left: 10px;

        .bet-coin-item {
            top: 6px;
            height: 34px;
            margin-left: 10px;
            float: left;
            opacity: .6;
        }

        .active {
            //border: 2px solid #ccc;
            box-shadow: 0 1px 4px 4px rgba(255, 0, 0, .6); /* 阴影 */
            border-radius: 50%;
            opacity: 1;
        }
    }

    .coin-set {
        margin-top: 5px;
        margin-right: 10px;
        width: 36px;
        float: right;
    }

}

.bot-btn-box {
    width: 100%;
    //margin-top: 10px;
    display: flex;
    justify-content: space-between;

    .btn {
        background-color: red;
        display: flex;
        align-items: center;

        background-color: rgba(24, 25, 32, 0.7);
        border-right: 1px solid #3e3e3e;
        //width: 20%;
        height: 30px;

        .btn-box {
            display: flex;
            align-items: center;
        }
    }

    .icon {
        font-size: 24px;
        align-items: center;
    }

    .icon-1 {
        color: #909092;
        font-size: 36px;
    }

    .icon-5 {
        color: #fff;
        font-size: 36px;
    }

    .btn_active {
        //color: #666565;
        //background-color: #3B413A;
        background-image: url("../components/img/room/active.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
}

.lu-tu {
    width: 100%;
    height: 100px;
    background-color: #EBC578;
}

.bottom {
    width: 100%;
    height: 10px;
    //background-color: #008A71;
}

.tableStatus {
    background-color: rgba(0, 0, 0, .4);
    color: #fff;
    border-radius: 50%;
}

.desc-num {
    font-size: 16px;
}

/deep/ .ivu-modal-mask {
    background-color: rgba(55, 55, 55, .3);
}

/deep/ .ivu-btn {
    border-radius: 0
}

/deep/ .ivu-btn-primary {
    border-color: transparent
}

/deep/ .ivu-spin-fix {
    background-color: rgba(255, 255, 255, .5);
}

/deep/ .ivu-spin-dot {
    width: 60px;
    height: 60px;
}

.selImgCss {
    padding: 2px;
    //border: 2px solid red;
    box-shadow: 0px 1px 4px 4px rgba(255, 0, 0, 1);
    border-radius: 50%;
}

.bet-box {
    font-size: 10px;
    width: 100%;
    height: 48%;
    background-image: url("../assets/room/bet-box-bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .bet-box-con {
        width: 96%;
        height: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .bet-top {
        height: 80%;
        width: 100%;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;

        .bet-left {
            width: 58%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .bet-left-t {
                display: flex;
                width: 100%;
                height: 66%;
                flex-wrap: wrap;
                border-radius: 6px;
                overflow: hidden;
                justify-content: space-around;

                .item {
                    background-color: rgba(0, 0, 0, .3);
                    width: 32.5%;
                    height: 24.5%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }
            }

            .bet-left-b {
                height: 32%;
                width: 100%;
                display: flex;
                border-radius: 6px;
                overflow: hidden;
                justify-content: space-between;

                .jiao {
                    width: 66%;
                    height: 100%;
                    display: flex;
                    justify-content: space-around;
                    flex-wrap: wrap;

                    .item {
                        background-color: rgba(0, 0, 0, .3);
                        height: 48%;
                        width: 49%;
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        justify-content: center;
                    }
                }

                .dan_s {
                    width: 32%;
                    height: 100%;
                    display: flex;
                    justify-content: space-around;
                    flex-wrap: wrap;

                    .item {
                        background-color: rgba(0, 0, 0, .3);
                        height: 48%;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;

                        & > span:nth-child(2) {
                            font-size: 10px;
                        }
                    }
                }

            }
        }

        .bet-right {
            width: 41%;
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            border-radius: 6px;
            overflow: hidden;
            justify-content: space-evenly;

            .item {
                background-color: rgba(0, 0, 0, .3);
                width: 49%;
                height: 16%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .bet-bottom {
        height: 12%;
        width: 100%;
        margin-top: 1%;
        border-radius: 6px;
        overflow: hidden;
        display: flex;
        justify-content: space-around;

        .item {
            font-size: 16px;
            background-color: rgba(0, 0, 0, .3);
            width: 24%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center !important;
            padding: 0;

            & > span:nth-child(2) {
                font-size: 10px;
            }
        }
    }
}


.item {
    position: relative;
    padding-top: 10px;
    justify-content: flex-start !important;

    .bet-show {
        bottom: 0;
        height: 14px;
        width: 100%;
        position: absolute;
        color: #fff;
        display: flex;
        justify-content: center;
        font-size: 8px;

        & > span {
            left: 0;
            position: absolute;
            min-width: 20px;
            height: 10px;
            line-height: 10px;
            color: #fff;
            bottom: 0;
        }
    }

    .bet-show1 {
        top: 0;
        left: 0;
        height: 20px;
        width: 100%;
        position: absolute;
        color: #fff;
        display: flex;
        justify-content: center;
        font-size: 8px;

        & > span {
            position: absolute;
            top: 0;
            left: 0;
            line-height: 5px;
            display: block;
            height: 16px;
            padding: 5px;
            border: 1px solid rgba(255, 255, 255, .5);
            background-color: rgba(255, 0, 0, .3);
            border-radius: 6px;
            color: #fff;
            margin-bottom: 5px;
        }
    }

    .my-bet {
        top: 20px;
        position: absolute;
        color: #fff;
        display: flex;
        justify-content: center;
        font-size: 10px;
        border: 1px solid #fff;
        background-color: rgba(9, 135, 16, .6);
        align-items: center;
        border-radius: 6px;
        padding: 0 5px;

        img {
            height: 10px;
        }

    }
}


.item:active {
    opacity: 0.7;
}

.col-s { //呼吸灯
    background-color: rgba(173, 255, 47, 0.2);
    animation: breathingAnimation 100s infinite forwards;
}

@keyframes breathingAnimation {
    0% {
        background-color: rgba(173, 255, 47, 0.2);
    }
    25% {
        background-color: rgba(173, 255, 47, 0.5);
    }
    50% {
        background-color: rgba(173, 255, 47, 0.2);
    }
    100% {
        background-color: rgba(173, 255, 47, 0.5);
    }
}


</style>
