<template>
  <div class="login_form">
    <!-- <div class="box">
      <img src="../assets/newImage/back.png" alt="" style="width:20px;height:15px;" @click="goback">
      <span style="color:aliceblue;font-size:20px;margin-left: 40px;">
        {{$t('uc.regist.regist')}}
      </span>
    </div> -->

    <div class="header_nav">
  <img src="../assets/netmage/logo08.png" style="width: 225px;">
</div>
    <div class="login_right">
     
      <Form v-if="allowRegister" ref="formInline" :model="formInline" :rules="ruleInline" inline>

        <!-- <FormItem >
          <img src="../assets/netmage/logo08.png" style="width: 125px;height:119px">
        </FormItem> -->

        <!-- <FormItem prop="username" style="display:none;">
          <Input type="text" v-model="formInline.username" :placeholder="$t('uc.regist.username')">
          </Input>
        </FormItem> -->
        <FormItem prop="user" style="margin-top:20px">
          <Input type="text" style="width:300px; " size="large" v-model="formInline.user" :placeholder="$t('uc.login.usertip')">
            <Select v-model="country" slot="prepend" style="width: 65px;">
            <Option value="+855" label="+855"><span>+855</span><span style="margin-left:10px;color:#ccc">{{$t('uc.regist.cambodia')}}</span></Option>
              <Option value="+86" label="+86"><span>+86</span><span style="margin-left:10px;color:#ccc">{{$t('uc.regist.china')}}</span></Option>
              <Option value="+65" label="+65"><span>+65</span><span style="margin-left:10px;color:#ccc">{{$t('uc.regist.singapore')}}</span></Option>
              <Option value="+82" label="+82"><span>+82</span><span style="margin-left:10px;color:#ccc">{{$t('uc.regist.korea')}}</span></Option>
              <Option value="+81" label="+81"><span>+81</span><span style="margin-left:10px;color:#ccc">{{$t('uc.regist.japan')}}</span></Option>
              <Option value="+66" label="+66"><span>+66</span><span style="margin-left:10px;color:#ccc">{{$t('uc.regist.thailand')}}</span></Option>
              <Option value="+44" label="+44"><span>+44</span><span style="margin-left:10px;color:#ccc">{{$t('uc.regist.uk')}}</span></Option>
              <Option value="+84" label="+84"><span>+84</span><span style="margin-left:10px;color:#ccc">{{$t('uc.regist.vietnam')}}</span></Option>
              <Option value="+62" label="+62"><span>+62</span><span style="margin-left:10px;color:#ccc">{{$t('uc.regist.india')}}</span></Option>
              <Option value="+39" label="+39"><span>+39</span><span style="margin-left:10px;color:#ccc">{{$t('uc.regist.italy')}}</span></Option>
              <Option value="+852" label="+852"><span>+852</span><span style="margin-left:10px;color:#ccc">{{$t('uc.regist.hk')}}</span></Option>
              <Option value="+60" label="+60"><span>+60</span><span style="margin-left:10px;color:#ccc">{{$t('uc.regist.malaysia')}}</span></Option>
              <Option value="+886" label="+886"><span>+886</span><span style="margin-left:10px;color:#ccc">{{$t('uc.regist.taiwan')}}</span></Option>
              <Option value="+673" label="+673"><span>+673</span><span style="margin-left:10px;color:#ccc">{{$t('uc.regist.wenlai')}}</span></Option>
            </Select>
          </Input>
        </FormItem>

        <FormItem prop="code" style="width:300px;margin-top:-5px" v-show="showCode">
          <Input type="text" size="large" v-model="formInline.code"  :placeholder="$t('uc.regist.smscode')">
          </Input>
          <input id="sendCode" @click="sendCode();"  type="Button" style="margin-top:10px;height: 100%;" shape="circle" :value="sendcodeValue" :disabled='codedisabled'/>
         
        </FormItem>
        <FormItem prop="password"  style="width:300px;margin-top:-5px">
          <Input type="password"  size="large" v-model="formInline.password" password :placeholder="$t('uc.regist.pwd')">
          </Input>
        </FormItem>
        <FormItem prop="repassword" style="width:300px;margin-top:-5px" >
          <Input type="password" size="large" v-model="formInline.repassword" password :placeholder="$t('uc.regist.repwd')">
          </Input>
        </FormItem>
        <FormItem prop="promotion" style="width:300px;margin-top:-5px">
          <Input type="text" size="large" v-model="formInline.promotion">
            <span slot="prepend">{{$t('uc.regist.promotion')}} :</span>
          </Input>
        </FormItem>
        <!-- <div class="check-agree" style="">
          <label>
            <Checkbox v-model="agree">{{$t('uc.regist.agreement')}}</Checkbox>
          </label>
          <a v-if="lang=='zh_CN'" href="/helpdetail?cate=1&id=5&cateTitle=常见问题" target="_blank" style="">《{{$t('uc.regist.userprotocol')}}》</a>
          <a v-if="lang=='zh_CN'" href="/helpdetail?cate=1&id=35&cateTitle=Privacy Policy" target="_blank" style="">《{{$t('uc.regist.userprotocol')}}》</a>
        </div> -->
        <FormItem>
          <Button class="register_btn"  @click="handleSubmit('formInline')" :disabled="registing">{{$t('uc.regist.regist')}}</Button>
        </FormItem>
        <FormItem prop="promotion" style="width:300px;margin-top:-5px">
          <Button type="warning" ghost @click="goback">{{$t('common.loginman')}}</Button>
        </FormItem>
      </Form>
    </div>
    
  </div>
</template>
<style scoped lang="scss">
#page4 {
  background: transparent;
  padding: 80px 0 80px 0;
  ul {
    width: 99%;
    margin: 0 auto;
    li {
      flex: 0 0  25%;
      display: inline-block;
      width: 100%;
      padding: 0 15px;
      div {
        width: 130px;
        height: 130px;
        border-radius: 50%;
        vertical-align: middle;
        text-align: center;
        margin: 0 auto;
        img {
          height: 125px;
          margin-top: 8px;
        }
      }
      p {
        font-size: 14px;
        margin: 20px 0;
        text-align: center;
        color: #828ea1;
      }
      p.title {
        color: #fff;
        font-size: 18px;
        font-weight: 400;
      }
    }
  }
}

/deep/ .ivu-select-single .ivu-select-selection .ivu-select-arrow {
    right: 2px;
    // background-color: rgba(0, 0, 0, 0.6);
}

/deep/ .ivu-input-group-prepend {
    background-color:rgba(0, 0, 0, 0.6);
    border: 0;
    font-size: small;

}

// .ivu-input-group-large .ivu-input, .ivu-input-group-large>.ivu-input-group-append, .ivu-input-group-large>.ivu-input-group-prepend 

/deep/ .ivu-select{
    background-color: transparent;
    color: aliceblue;
    font-size: small;
}

.ivu-form-item-error .ivu-select-arrow {
    color: #808695;
}

/deep/ .ivu-input{
  background-color: rgba(0, 0, 0, 0.6);
  border: 0;
  font-size: small;
  color: aliceblue

//   color: #898989
}

.header_nav{
    margin-top:60px;
    
  }
.box{
  position: fixed;
  top: 0;
  display: flex;
  height: 50px;
  padding: 10px;
  align-items: flex-start;
  align-items: baseline;
  background: url(../assets/netmage/xxyz.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 200;
   width: 100%;
}
.login_form {
  background: url(../assets/netmage/bj345.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 350% 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  .login_right {
    padding: 20px 30px 20px 30px;
    position: absolute;
    background: transparent;
    width: 100%;
    height: 100%;
   
    top: 35vh;
 
    form.ivu-form.ivu-form-label-right.ivu-form-inline {
      .login_title{
        height: 70px;
        color: #fff;
        font-size: large;
      }

      #sendCode {
            position: absolute;
            border: 1px solid #101010;
            background: transparent;
            top: -10px;
            outline: none;
            right: 0;
            width: 30%;
            color: #d5851d;
            cursor: pointer;
          }
    }
  }
}
.ivu-btn-group>.ivu-btn.active, .ivu-btn-group>.ivu-btn:active, .ivu-btn-group>.ivu-btn:hover{
  background: transparent!important;
  border-color: transparent!important;
  color: #f0ac19!important;
}
.register_btn {
        width: 300px;
        margin-top: 20px;
        background: url(../assets/netmage/usdtxy.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        outline: none;
        font-weight: 600;
        font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        font-size: 18px;
        border: none;
        &:focus {
          -moz-box-shadow: 0px 0px 0px #fff, 0px 0px 0px #fff;
          -webkit-box-shadow: 0px 0px 0px #fff, 0px 0px 0px #fff;
          box-shadow: 0px 0px 0px #fff, 0px 0px 0px #fff;
        }
      }

.login_title {
  text-align: center;
  height: 80px;
  font-size: 25px;
}

#captcha {
  width: 100%;
  display: inline-block;
}
.show {
  display: block;
}
.hide {
  display: none;
}
#notice {
  color: red;
}
#wait {
  text-align: left;
  color: #666;
  margin: 0;
}
.tel-title {
  font-size: 25px;
}
.login_left {
  display: none;
}
</style>
<script>
//   import gtInit from '../../assets/js/gt.js';

export default {
  data() {
    const validateUser = (rule, value, callback) => {
      if (this.changeActive == 0) {
        // var reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
        if (value == "") {
          callback(new Error(this.$t("uc.regist.teltip")));
        }else {
          callback();
        }
      } else {
        var reg = /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/;
        reg = /^(\w)+(\.\w+)*@(\w)+((\.\w{2,3}){1,3})$/;
        if (value == "") {
          callback(new Error(this.$t("uc.regist.emailtip")));
        } else if (!reg.test(this.formInline.user)) {
          callback(new Error(this.$t("uc.regist.emailerr")));
        } else {
          callback();
        }
      }
    };
    const validateRepassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("uc.regist.confirmpwdtip")));
      } else if (value !== this.formInline.password) {
        callback(new Error(this.$t("uc.regist.confirmpwderr")));
      } else {
        callback();
      }
    };
    return {
      country: "+855",
      country1: "台湾",
      codedisabled:false,
      sendcodeValue: this.$t("uc.regist.sendcode"),
      isRegister: false,
      ticket: "",
      randStr: "",
      registing: false,
      captchaObj: null,
      modal1: false,
      agree: true,
      allowRegister: true,
      buttonLists: [
        {
          text: this.$t("uc.regist.telregist")
        },
        {
          text: this.$t("uc.regist.emailregist")
        }
      ],
      areas: [],
      changeActive: 0,
      showCode: true,
      countdown: 60,
      formInline: {
        username: "",
        country: "",
        user: "",
        code: "",
        areaCode: "",
        password: "",
        repassword: "",
        promotion: ""
      },
      ruleInline: {
        user: [{ validator: validateUser, trigger: "blur" }],
        code: [
          {
            required: true,
            message: this.$t("uc.regist.smscodetip"),
            trigger: "blur"
          }
        ],
        password: [
          {
            required: true,
            message: this.$t("uc.regist.pwdtip"),
            trigger: "blur"
          },
          {
            type: "string",
            min: 6,
            message: this.$t("uc.regist.pwdmsg"),
            trigger: "blur"
          }
        ],
        repassword: [{ validator: validateRepassword, trigger: "blur" }]
      },
      key: "",
      code: "",
      timercode:''
    };
  },
  watch: {
    changeActive: function() {
      this.$refs["formInline"].resetFields();
      // if (val == 0) this.initGtCaptcha();
    },
    lang: function() {
      this.updateLangData();
    }
  },
  computed: {
    lang: function() {
      return this.$store.state.lang;
    },
    isLogin: function() {
      return this.$store.getters.isLogin;
    }
  },
  created: function() {
    window.scrollTo(0, 0);
    // var oDiv = document.getElementById("example-navbar-collapse");
    // oDiv && (oDiv.className = "navbar-collapse collapse");
    this.init();
    this.actives(this.changeActive);
    if(this.$store.getters.currencyType != undefined && this.$store.getters.currencyType != "" && this.$store.getters.currencyType != null){
        this.formInline.promotion = this.$store.getters.currencyType;
    }else{
        this.formInline.promotion = "";
    }

    
  },
  methods: {
    goback() {
      //获取个人安全信息
     this.$router.replace("/");
     
    },
    updateLangData() {
      this.buttonLists = [
        {
          text: this.$t("uc.regist.telregist")
        },
        {
          text: this.$t("uc.regist.emailregist")
        }
      ];

      if (this.changeActive == 0) {
        this.key = this.$t("uc.regist.telno");
      } else {
        this.key = this.$t("uc.regist.email");
      }
    },
    init() {
      // this.$store.commit("navigate", "nav-other");
      // this.$store.state.HeaderActiveName = "0";
      if (this.isLogin) {
        this.$router.push("/");
      }
      // window.document.title = (this.lang == "zh_CN" ? "新用户注册 - " : "New Register - ");
      // this.getAreas();
      // this.initGtCaptcha();
    },
    
    onAreaChange(value) {
      for (var i = 0; i < this.areas.length; i++) {
        if (this.areas[i].zhName == value) {
          this.formInline.areaCode = this.areas[i].areaCode;
        }
      }
    },
    getAreas() {
      this.$http.post(this.host + this.api.common.area).then(response => {
        var resp = response.body;
        this.areas = resp.data;
        this.formInline.country = this.areas[0].zhName;
        this.formInline.areaCode = this.areas[0].areaCode;
      });
    },
    actives: function(index) {
      this.changeActive = index;
      if (this.changeActive == 0) {
        this.showCode = true;
        this.key = this.$t("uc.regist.telno");
        this.ruleInline.code = [
          {
            required: true,
            message: this.$t("uc.regist.smscodetip"),
            trigger: "blur"
          }
        ];
      } else {
        this.showCode = false;
        this.key = this.$t("uc.regist.email");
        this.ruleInline.code = [];
      }
    },


    handleSubmit(name) {
      this.$refs[name].validate(valid => {
        if (valid) {
          if (this.agree == true) {
            if (this.changeActive == 1) {
              this.openValidateModal();
            } else {
              // if (this.isRegister) {
                this.registing = true;
                var params = {};
                let xy = this.country.substring(1, this.country.length)
                params["mobile"] = this.formInline.user;
                // params["username"] = this.formInline.username + this.formInline.user;
                params["password"] = this.formInline.password;
                params["agentId"] = this.formInline.promotion
           // 邀请码
                params["mask"] = this.formInline.code;
                params["code"] = xy;//"中国";  
                params["deviceInfo"] = "3e3c41b2-62fd-48b3-b51e-fd202c19b456";
              let that = this
                this.$http
                  .post(this.host + this.api.uc.register, params)
                  .then(response => {
                    this.registing = false;
                    var resp = response.body;
                    if (resp.code == "0000") {
                    
                        that.$Notice.success({
                          title: this.$t("common.tip"),
                          desc: resp.mesg
                        });
                        that.$store.commit("setMember", resp.data);
                        that.$store.commit('setCode', xy);
                localStorage.setItem('TOKEN', resp.data.token);
                that.$router.push({name:"About"}); 
                clearInterval(that.timercode);
             
                    } else {
                      this.$Notice.error({
                        title: this.$t("common.tip"),
                        desc: resp.mesg
                      });
                    }
                  });
          
            }
          } else {
            this.$Notice.error({
              title: this.$t("common.tip"),
              desc: this.$t("uc.regist.agreementtip")
            });
          }
        }else{
          console.log("999");
        }
      });
    },
    settime() {
      this.sendcodeValue = this.$t("uc.regist.resendcode") + this.countdown + ")";
      this.codedisabled = true;
      var _this = this;
      _this.timercode = setInterval(() => {
        _this.countdown--;
        _this.sendcodeValue = _this.$t("uc.regist.resendcode") + _this.countdown + ")";
        if (this.countdown <= 0) {
          clearInterval(_this.timercode);
          _this.codedisabled = false;
          _this.sendcodeValue = _this.$t("uc.regist.sendcode");
          _this.countdown = 120;
        }
      }, 1000);
    },
    // sendCode() {
    //   var mobilePhone = this.formInline.user;
    //   let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
    //   // if (mobilePhone == "" || !reg.test(mobilePhone)) {
    //   if (mobilePhone == "") {
    //     this.$Message.error(this.$t("uc.regist.teltip"));
    //     return;
    //   } else {
    //     this.initGtCaptcha();
    //   }
    // },


//     areaCode (string, optional): 手机号区号 ,
// mobilePhone (string): 手机号码 ,
// sendType 
    sendCode() {
      var params = {};
       
          let xy = this.country.substring(1, this.country.length)
         
      params["mobilePhone"] = this.formInline.user;
      params["areaCode"] = xy;
      params["sendType"] = 0;
      // var reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      // reg.test(params["phone"]) && this.$http.post(this.host + "/uc/mobile/code", params).then(response => {
      this.$http.post(this.host + this.api.uc.captcha, params).then(response => {
            var resp = response.body;
            resp.code == "0000" && this.$Notice.success({title: this.$t("common.tip"),desc: resp.mesg});
            resp.code == "0000" && this.settime();
            resp.code != "0000" && this.$Notice.error({title: this.$t("common.tip"),desc: resp.mesg});
          });
      // !reg.test(params["phone"]) &&this.$Notice.error({title: this.$t("common.tip"),desc: this.$t("uc.finance.withdraw.telerr")});
    }
  }
};
</script>
